import {IGraphDataDto} from "../../common/apis/GraphData";
import * as d3 from "d3";
import LinksRenderer from "./renderer/LinksRenderer";
import NodesRenderer from "./renderer/NodesRenderer";
import MarkersManager from "./manager/MarkersManager";
import ModelManager, {INode} from "./manager/ModelManager";
import SimulationManager from "./manager/SimulationManager";
import SvgElementManager from "./manager/SvgElementManager";
import ZoomManager from "./manager/ZoomManager";
import EventManager from "./manager/EventManager";
import NodeAnchorManager from "./manager/NodeAnchorManager";
import NodeMenuManager from "./manager/NodeMenuManager";
import NodeSelectionManager from "./manager/NodeSelectionManager";
import {INodeEventListener} from "./event/Listener";
import {EventType, INodeEvent} from "./event/Event";
import {NodeMenuIconType} from "./manager/nodemenu/NodeMenuFactory";
import {DEFAULT_LINE_COLOR} from "../../common/diagrameditor/common/UIConstants";

export interface ElementApi {
    iconTooltips: {[key in keyof typeof NodeMenuIconType]: string}
    explodeElement: (id: string) => void,
    showElementDetail: (id: string) => void,
}

export interface GraphDataChartApi {
    renderChart(entityId: string, data: IGraphDataDto, explodedElementIds: Array<string>, svgRef: SVGSVGElement, parentRect: DOMRect): void,
    destroyChart(): void,
    zoomIn(event: any): void,
    zoomOut(event: any): void,
    anchor(node: INode, event: any): void,
    unanchor(node: INode, event: any): void,
    resetZoom(event: any): void,
    maximize(event: any): void,
    minimize(event: any): void,
}

export class ChartRenderContext {
    constructor(public readonly graphDataChanged: boolean,
                public readonly explodedElementIds: Array<string>) {
    }
}

export class GraphDataChart implements INodeEventListener {
    public static LINK_COLOR: string = DEFAULT_LINE_COLOR;

    private chartApi: GraphDataChartApi;
    private elementApi: ElementApi;

    private eventManager: EventManager;
    private svgElementManager: SvgElementManager;
    private simulationManager: SimulationManager;
    private modelManager: ModelManager;
    private nodeAnchorManager: NodeAnchorManager;
    private nodeMenuManager: NodeMenuManager;
    private nodeSelectionManager: NodeSelectionManager;
    private zoomManager: ZoomManager;
    private markersManager: MarkersManager;
    private linksRenderer: LinksRenderer;
    private nodesRenderer: NodesRenderer;

    public static create(rootElementIds: Array<string>, elementApi: ElementApi): GraphDataChartApi {
        return new GraphDataChart(rootElementIds, elementApi).chartApi;
    }

    private constructor(rootElementIds: Array<string>, elementApi: ElementApi) {
        const chart = this;
        this.chartApi = {
            renderChart(entityId: string, data: IGraphDataDto, explodedElementIds: Array<string>, svgRef: SVGSVGElement, parentRect: DOMRect) {
                chart.renderChart(entityId, data, explodedElementIds, svgRef, parentRect);
            },
            destroyChart() {
                chart.destroyChart();
            },
            zoomIn(event: any) {
                chart.zoomIn(event);
            },
            zoomOut(event: any) {
                chart.zoomOut(event);
            },
            anchor(node: INode, event: any) {
                chart.anchor(node, event);
            },
            unanchor(node: INode, event: any) {
                chart.unanchor(node, event);
            },
            resetZoom(event: any) {
                chart.resetZoom(event);
            },
            maximize(event: any) {
                chart.maximize(event);
            },
            minimize(event: any) {
                chart.minimize(event);
            }
        }
        this.elementApi = elementApi;

        this.eventManager = new EventManager();
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MOUSE_DBLCLICK);

        this.svgElementManager = new SvgElementManager(this.eventManager);
        this.simulationManager = new SimulationManager(this.eventManager);
        this.modelManager = new ModelManager(rootElementIds, this.eventManager);
        this.zoomManager = new ZoomManager(this.eventManager);
        this.nodeSelectionManager = new NodeSelectionManager(this.eventManager);
        this.nodeAnchorManager = new NodeAnchorManager(this.eventManager);
        this.nodeMenuManager = new NodeMenuManager(this.eventManager, this.chartApi, elementApi);
        this.markersManager = new MarkersManager(this.eventManager);
        this.linksRenderer = new LinksRenderer(this.eventManager);
        this.nodesRenderer = new NodesRenderer(elementApi, this.eventManager);
    }

    /*
        PUBLIC API FUNCTIONS
     */

    private renderChart(entityId: string, data: IGraphDataDto, explodedElementIds: Array<string>, svgRef: SVGSVGElement, parentRect: DOMRect) {
        if (!GraphDataChart.isDataEmpty(data)) {

            // svgRef is always new -> always rerender chart into the newly created svg element
            const renderContext = new ChartRenderContext(this.modelManager.getData() !== data, explodedElementIds);

            // reset previously started simulation when such exists
            this.simulationManager.stopSimulation();

            // init svg element and chart group
            this.svgElementManager.init(svgRef, parentRect);
            const svg = this.svgElementManager.getSvg() as SVGSVGElement;
            const svgElem = this.svgElementManager.getSvgElement() as d3.Selection<SVGSVGElement, unknown, null, undefined>;
            const chartGroup = this.svgElementManager.getChartGroup() as d3.Selection<SVGGElement, unknown, null, undefined>;
            const defsElement = this.svgElementManager.getDefsElement() as d3.Selection<SVGDefsElement, unknown, any, any>;

            this.markersManager.init(defsElement);
            this.nodeMenuManager.init(defsElement, chartGroup, renderContext);
            this.zoomManager.init(svg, svgElem, chartGroup);

            this.modelManager.updateModel(data, entityId);
            this.modelManager.setExplodedElementIds(explodedElementIds);

            const nodes = this.modelManager.getNodes();
            const links = this.modelManager.getLinks();

            // create links
            const linkElems = this.linksRenderer.render(chartGroup, links);
            // create node groups
            const nodeElems = this.nodesRenderer.render(chartGroup, nodes, this.modelManager.getRootElementIds(), this.modelManager.getExplodedElementIds());
            // create simulation
            this.simulationManager.createSimulation(chartGroup, nodes, links, linkElems, nodeElems, renderContext.graphDataChanged);
        }
    }

    private destroyChart() {
        this.simulationManager.stopSimulation();
    }

    private zoomIn(event: any) {
        this.eventManager.publishChartEvent({type: EventType.CHART_ZOOM_IN, event: event});
    }

    private zoomOut(event: any) {
        this.eventManager.publishChartEvent({type: EventType.CHART_ZOOM_OUT, event: event});
    }

    private resetZoom(event: any) {
        this.eventManager.publishChartEvent({type: EventType.CHART_ZOOM_RESET, event: event})
    }

    private anchor(node: INode, event: any) {
        this.eventManager.publishNodeEvent({type: EventType.NODE_ANCHOR_MENUITEM_CLICKED, event: event, node: node});
    }

    private unanchor(node: INode, event: any) {
        this.eventManager.publishNodeEvent({type: EventType.NODE_UNANCHOR_MENUITEM_CLICKED, event: event, node: node})
    }

    private maximize(event: any) {
        this.eventManager.publishChartEvent({type: EventType.CHART_MAXIMIZE, event: event})
    }

    private minimize(event: any) {
        this.eventManager.publishChartEvent({type: EventType.CHART_MINIMIZE, event: event})
    }

    handleNodeEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_MOUSE_DBLCLICK) {
            const nodeId = event.node.id;
            this.modelManager.getExplodedElementIds()?.indexOf(nodeId) === -1 && this.elementApi.explodeElement(nodeId);
        }
    }

    private static isDataEmpty(data: IGraphDataDto) {
        return data.elementsData?.length === 0 && data.relationshipsData?.length === 0;
    }
}