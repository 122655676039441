import {IconButton, InputAdornment, Tooltip} from "@mui/material";
import React from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import clsx from "clsx";
import TextField from "./fields/textfield/TextField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            "& .MuiOutlinedInput-root": {
                paddingRight: theme.spacing(.5),
            },
            "& .MuiInputBase-input.Mui-disabled": {
                cursor: "pointer",
                color: theme.palette.text.primary,
                WebkitTextFillColor: theme.palette.text.primary,
            },
        }
    })
);

interface TextFieldWithAdornmentIconProps {
    key?: string,
    id?: string,
    icon: JSX.Element,
    className?: string,
    required: boolean,
    value?: string,
    label: string,
    errorMessage?: string,
    tooltipTitle: string,
    onClick: () => void,
    clearable?: boolean,
    onClearButtonClick?: () => void,
}

export function TextFieldWithAdornmentIcon(props: TextFieldWithAdornmentIconProps) {

    const {key, id, icon, className, required, value, label, errorMessage, onClick, tooltipTitle, clearable, onClearButtonClick} = props;
    const classes = useStyles();

    return(
        <TextField key={key}
                   id={id}
                   className={clsx(className, classes.textField)}
                   required={required}
                   value={value}
                   label={label}
                   InputProps={{
                       disabled: true,
                       endAdornment: <InputAdornment position="end">
                           <Tooltip title={tooltipTitle}>
                               <IconButton size={"small"}>{icon}</IconButton>
                           </Tooltip>
                       </InputAdornment>
                   }}
                   errorMessage={errorMessage}
                   onClick={onClick}
                   clearable={clearable}
                   onClearButtonClick={onClearButtonClick}
        >
        </TextField>
    )
}

type TextFieldWithFixedAdornmentIcon = Omit<TextFieldWithAdornmentIconProps, 'icon'>;

export function TextFieldWithMoreIcon(props: TextFieldWithFixedAdornmentIcon) {
    return (
        <TextFieldWithAdornmentIcon icon={<MoreHorizIcon/>} {...props} />
    );
}
