import {Grid, Paper} from "@mui/material";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import HierarchyTree from "./HierarchyTree";
import {_transl} from "../../../../store/localization/TranslMessasge";
import DiagramDetailPanel from "../diagrams/detail/DiagramDetailPanel";
import ElementDetailPanel from "../elementdetail/ElementDetailPanel";
import RouteDefinitionUtils from "../../../../common/routedefinition/RouteDefinitionUtils";
import Divider from "@mui/material/Divider";
import HierarchyTreeChooser from "./HierarchyTreeChooser";
import {HierarchyTreeItemDetailDto} from "./service/HierarchyTreeItemDetailDto";
import hierarchyTreeService from "./service/HierarchyTreeService";
import createHierarchyTreeController, {HierarchyTreeController} from "./controller/HierarchyTreeController";
import {HierarchyTreeDto, HierarchyTreeInfoDto} from "./service/HierarchyTreeDto";
import {HierarchyTreeDeleteDialog} from "./dialogs/HierarchyTreeDeleteDialog";
import {HierarchyTreeUpdateDto} from "./service/HierarchyTreeUpdateDto";
import {HierarchyTreeUpdateDialog} from "./dialogs/HierarchyTreeUpdateDialog";
import {HierarchyTreeCreateDialog} from "./dialogs/HierarchyTreeCreateDialog";
import createDiagramController from "../diagrams/controller/DiagramController";
import {ExtGridTranslationKey} from "../../../../components/grid/ExtGridTranslationKey";
import clsx from "clsx";
import {IApplicationState} from "../../../../store/Store";
import {useSelector} from "react-redux";
import Snackbar from "../snackbar/Snackbar";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import ConfigurationContext, {Configuration} from "../../../../common/ConfigurationContext";
import {HierarchyTreeDataType} from "./service/HierarchyTreeDataType";
import {ValidationError} from "../../../../common/ValidationError";
import {GraphQueryExecutionErrorCode} from "../graphquery/GraphQueryExecutionErrorCode";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import {GraphQueryTranslationKey} from "../graphquery/GraphQueryTranslationKey";
import {HierarchyTreeCreateDto} from "./service/HierarchyTreeCreateDto";
import HierarchyTreeCrudOpsContext, {HierarchyTreeCrudOps} from "./HierarchyTreeCrudOpsContext";
import HierarchyTreeReorderDialog from "./dialogs/HierarchyTreeReorderDialog";
import {HierarchyTreeEventType, SubtreeReloadRequestEvent} from "./HierarchyTreeEvents";
import EventManagerContext from "../../../../common/event/EventManagerContext";
import {CommonTranslation} from "../CommonTranslation";
import {Panel, PanelGroup, PanelResizeHandle} from "react-resizable-panels";
import HierarchyTreeUrlReferenceUpdateDialog from "./dialogs/HierarchyTreeUrlReferenceUpdateDialog";
import HierarchyTreeElementReportReferenceUpdateDialog from "./dialogs/HierarchyTreeElementReportReferenceUpdateDialog";
import {HierarchyTreeDataDto, ReferenceTreeDataDto, ReferenceType} from "./service/HierarchyTreeDataDto";
import {useNavigate} from "react-router-dom";
import {GraphQueryTreeErrorCode} from "./graphquery/GraphQueryTreeErrorCode";
import {HierarchyTreeTranslationKey} from "./HierarchyTreeTranslationKey";

interface CreateDialogAttrs {
    open: boolean;
    parentTreeId?: string | null;
    parentTreePath?: string;
}

interface UpdateDialogAttrs extends CreateDialogAttrs {
    treeId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: theme.spacing(1, 1, 0, 1)
        },
        body: {
            padding: theme.spacing(1, 1, 1, 1)
        },
        page: CommonCssStyles.getRootPageStyles(theme),
        resizeHandle: {
            margin: theme.spacing(0, 1, 0, 1)
        },
        emptyMessage: {
            color: "gray"
        }
    })
);

export default function HierarchyTreePage() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [selectedRootTree, setSelectedRootTree] = useState<HierarchyTreeDto>();
    const [trees, setTrees] = useState<HierarchyTreeInfoDto[]>([]);
    const [treesReload, setTreesReload] = useState<number>(0);

    const [selectedDiagramId, setSelectedDiagramId] = useState<string>();
    const [selectedElementId, setSelectedElementId] = useState<string>();

    const [createDialogAttrs, setCreateDialogAttrs] = useState<CreateDialogAttrs>({open: false});
    const [updateUrlDialogAttrs, setUpdateUrlDialogAttrs] = useState<UpdateDialogAttrs>({open: false, treeId: ""});
    const [updateElementReportDialogAttrs, setUpdateElementReportDialogAttrs] = useState<UpdateDialogAttrs>({open: false, treeId: ""});
    const [updateDialogAttrs, setUpdateDialogAttrs] = useState<UpdateDialogAttrs>({open: false, treeId: ""});
    const [reorderDialogAttrs, setReorderDialogAttrs] = useState<UpdateDialogAttrs>({open: false, treeId: ""});
    const [deleteDialogAttrs, setDeleteDialogAttrs] = useState<UpdateDialogAttrs>({open: false, treeId: ""});
    const [graphQueryErrorMessage, setGraphQueryErrorMessage] = useState<string>();

    const hierarchyTreeController = useRef<HierarchyTreeController>(createHierarchyTreeController());
    const diagramController = createDiagramController();

    const canCreateHierarchyTrees = useSelector((state: IApplicationState) => state.user.userData?.userAcl.canCreateHierarchyTrees ?? false);

    const {featureStatuses} = useContext<Configuration>(ConfigurationContext);
    const eventManager = useContext(EventManagerContext);

    useEffect(() => {
        let isUnmounted = false;

        hierarchyTreeService.findRootTrees()
            .then((trees) => {
                if (!isUnmounted) {
                    setTrees(featureStatuses.graphQueryEnabled ? trees :
                        trees.filter(treeInfo => treeInfo.data.type !== HierarchyTreeDataType.QUERIES));
                }
            })
            .catch(err => {
                if (!isUnmounted) {
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), err);
                }
            });

        return () => {
            isUnmounted = true;
        }
    }, [treesReload, featureStatuses]);

    useEffect(() => {
        if (trees?.length > 0) {
            if (selectedRootTree === undefined || trees.findIndex(tree => tree.id === selectedRootTree.id) < 0) {
                hierarchyTreeService.findTree(trees[0].id)
                    .then(tree => setSelectedRootTree(tree))
                    .catch((error) => {
                        setSelectedRootTree(undefined);
                        Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), error);
                    });
            }
        }
    }, [trees, selectedRootTree]);

    useEffect(() => {
        setSelectedElementId(undefined);
        setSelectedDiagramId(undefined);
    }, [selectedRootTree]);

    function selectRootTree(treeId: string) {
        hierarchyTreeService.findTree(treeId)
            .then(tree => setSelectedRootTree(tree))
            .catch((error) => {
                if (error instanceof ValidationError && error.error.code === GraphQueryExecutionErrorCode.QUERY_EXECUTION_FAILED) {
                    setGraphQueryErrorMessage(error.error.message);
                } else {
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), error);
                }
            });
    }

    function reloadTree(treeId?: string | null, treePath?: string, childTreeId?: string) {
        if (treeId === null || treeId === undefined) {
            setTreesReload(treesReload + 1);
            if (childTreeId) {
                if (childTreeId === selectedRootTree?.id) {
                    reloadTree(childTreeId, "");
                }
            }
        } else if (treePath !== undefined) {
            if (treePath !== "" || treeId === selectedRootTree?.id) {
                const event: SubtreeReloadRequestEvent = {
                    type: HierarchyTreeEventType.SUBTREE_RELOAD_REQUEST,
                    subtreeId: treeId,
                    subtreePath: treePath
                };
                eventManager.publishEvent(event);
            }
        }
    }

    function onTreeItemLeafClicked(item: HierarchyTreeItemDetailDto) {
        if (item.diagramId) {
            setSelectedDiagramId(item.diagramId);
            setSelectedElementId(undefined);
        } else if (item.elementId) {
            setSelectedElementId(item.elementId);
            setSelectedDiagramId(undefined);
        }
    }

    const showCreateDialog = useCallback((parentTreeId?: string, parentTreePath?: string) => {
        setCreateDialogAttrs({
            open: true,
            parentTreeId: parentTreeId,
            parentTreePath: parentTreePath
        });
    }, []);

    function hideCreateDialog() {
        setCreateDialogAttrs({open: false});
    }

    function createTree(treeCreate: HierarchyTreeCreateDto<any>, parentTreeId?: string | null, parentTreePath?: string) {
        hierarchyTreeService.createTree(treeCreate)
            .then(() => {
                hideCreateDialog();
                reloadTree(parentTreeId, parentTreePath, treeCreate.parentId ?? undefined);
            })
            .catch(error => {
                if (error instanceof ValidationError && error.error.code === GraphQueryTreeErrorCode.WRONG_JUMPS) {
                    setGraphQueryErrorMessage(_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_WRONG_JUMPS_ERROR));
                } else {
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), error);
                }
            });
    }

    const showUpdateDialog = useCallback(<TreeData extends HierarchyTreeDataDto>(treeId: string, treeData: TreeData, parentTreeId?: string | null, parentTreePath?: string) => {
        setUpdateDialogAttrs({open: true, treeId: treeId, parentTreeId: parentTreeId, parentTreePath: parentTreePath});
    }, []);

    function hideUpdateDialog() {
        setUpdateDialogAttrs({open: false, treeId: ""});
    }

    function updateTree(treeId: string, treeUpdate: HierarchyTreeUpdateDto<any>, parentTreeId?: string | null, parentTreePath?: string) {
        hierarchyTreeService.updateTree(treeId, treeUpdate)
            .then(() => {
                hideUpdateDialog();
                reloadTree(parentTreeId, parentTreePath, treeId);
                Snackbar.success(_transl(CommonTranslation.DATA_SAVED_SUCESSFULLY));
            })
            .catch(error => {
                    if (error instanceof ValidationError && error.error.code === GraphQueryTreeErrorCode.WRONG_JUMPS) {
                        setGraphQueryErrorMessage(_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_WRONG_JUMPS_ERROR));
                    } else {
                        Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), error)
                    }
                }
            );
    }

    const showReorderDialog = useCallback((parentTreeId: string, parentTreePath?: string) => {
        setReorderDialogAttrs({open: true, treeId: "", parentTreeId: parentTreeId, parentTreePath: parentTreePath});
    }, []);

    function hideReorderDialog() {
        setReorderDialogAttrs({open: false, treeId: ""});
    }

    const showDeleteDialog = useCallback((treeId: string, parentTreeId?: string | null, parentTreePath?: string) => {
        setDeleteDialogAttrs({open: true, treeId: treeId, parentTreeId: parentTreeId, parentTreePath: parentTreePath});
    }, []);

    function hideDeleteDialog() {
        setDeleteDialogAttrs({open: false, treeId: ""});
    }

    function deleteTree(treeId: string, parentTreeId?: string | null, parentTreePath?: string) {
        hierarchyTreeService.deleteTree(treeId)
            .then(() => {
                hideDeleteDialog();
                reloadTree(parentTreeId, parentTreePath);
            })
            .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_REMOVE_DATA), err));
    }

    const showUpdateReferenceDialog = useCallback((treeId: string, treeData: ReferenceTreeDataDto, parentTreeId?: string | null, parentTreePath?: string) => {
        if (treeData.referenceType === ReferenceType.URL) {
            setUpdateUrlDialogAttrs({
                open: true,
                treeId: treeId,
                parentTreeId: parentTreeId,
                parentTreePath: parentTreePath,
            });
        } else if (treeData.referenceType === ReferenceType.ELEMENT_PAGE_REPORT) {
            setUpdateElementReportDialogAttrs({
                open: true,
                treeId: treeId,
                parentTreeId: parentTreeId,
                parentTreePath: parentTreePath,
            });
        }
    }, []);

    const showCreateUrlReferenceDialog = useCallback((parentTreeId: string, parentTreePath?: string) => {
        setUpdateUrlDialogAttrs({
            open: true,
            treeId: undefined,
            parentTreeId: parentTreeId,
            parentTreePath: parentTreePath,
        });
    }, []);

    function hideUpdateUrlReferenceDialog() {
        setUpdateUrlDialogAttrs({open: false});
    }

    const showCreateElementReportReferenceDialog = useCallback((parentTreeId: string, parentTreePath?: string) => {
        setUpdateElementReportDialogAttrs({
            open: true,
            treeId: undefined,
            parentTreeId: parentTreeId,
            parentTreePath: parentTreePath,
        });
    }, []);

    function hideCreateElementReportReferenceDialog() {
        setUpdateElementReportDialogAttrs({open: false});
    }

    function showElementDetail(elementId: string) {
        const path = RouteDefinitionUtils.resolveElementDetailPath(elementId);
        navigate(path);
    }

    const hierarchyTreeCrudOps = useMemo<HierarchyTreeCrudOps>(() => ({
        requestToCreateTree: showCreateDialog,
        requestToCreateUrlReference: showCreateUrlReferenceDialog,
        requestToCreateElementReportReference: showCreateElementReportReferenceDialog,
        requestToUpdateTree: showUpdateDialog,
        requestToUpdateReference: showUpdateReferenceDialog,
        requestToUpdateOrder: showReorderDialog,
        requestToDeleteTree: showDeleteDialog,
    }), [showCreateDialog, showCreateUrlReferenceDialog, showCreateElementReportReferenceDialog, showUpdateDialog, showUpdateReferenceDialog, showReorderDialog, showDeleteDialog]);

    return (
        <>
            <HierarchyTreeCreateDialog open={createDialogAttrs.open}
                                       parentTreeId={createDialogAttrs.parentTreeId}
                                       onConfirm={(treeCreate) => createTree(treeCreate, createDialogAttrs.parentTreeId, createDialogAttrs.parentTreePath)}
                                       onCancel={() => hideCreateDialog()} />

            <HierarchyTreeUpdateDialog open={updateDialogAttrs.open}
                                       treeId={updateDialogAttrs.treeId}
                                       onConfirm={(treeId, treeUpdate) => updateTree(treeId, treeUpdate, updateDialogAttrs.parentTreeId, updateDialogAttrs.parentTreePath)}
                                       onCancel={() => hideUpdateDialog()}/>

            <HierarchyTreeUrlReferenceUpdateDialog open={updateUrlDialogAttrs.open}
                                       treeId={updateUrlDialogAttrs.treeId}
                                       parentTreeId={updateUrlDialogAttrs.parentTreeId}
                                       onUpdated={(parentTreeId) => reloadTree(parentTreeId, updateUrlDialogAttrs.parentTreePath)}
                                       onClose={() => hideUpdateUrlReferenceDialog()}/>

            <HierarchyTreeElementReportReferenceUpdateDialog open={updateElementReportDialogAttrs.open}
                                                   treeId={updateElementReportDialogAttrs.treeId}
                                                   parentTreeId={updateElementReportDialogAttrs.parentTreeId}
                                                   onUpdated={(parentTreeId) => reloadTree(parentTreeId, updateElementReportDialogAttrs.parentTreePath)}
                                                   onClose={() => hideCreateElementReportReferenceDialog()}/>

            <HierarchyTreeReorderDialog open={reorderDialogAttrs.open}
                                        parentTreeId={reorderDialogAttrs.parentTreeId}
                                        onReordered={(parentTreeId) => reloadTree(parentTreeId, reorderDialogAttrs.parentTreePath)}
                                        onClose={() => hideReorderDialog()} />

            <HierarchyTreeDeleteDialog open={deleteDialogAttrs.open}
                                       treeId={deleteDialogAttrs.treeId}
                                       onConfirm={(treeId) => deleteTree(treeId, deleteDialogAttrs.parentTreeId, deleteDialogAttrs.parentTreePath)}
                                       onCancel={() => hideDeleteDialog()}/>

            <AlertDialog open={graphQueryErrorMessage !== undefined}
                         onClose={() => setGraphQueryErrorMessage(undefined)}
                         title={_transl(GraphQueryTranslationKey.ERROR_QUERY_EXECUTION_TITLE)}
                         text={`${_transl(GraphQueryTranslationKey.ERROR_QUERY_EXECUTION_MESSAGE)}: \n${graphQueryErrorMessage}`}
                         type={AlertDialogType.ERROR}
                         maxWidth={"sm"} />

            <HierarchyTreeCrudOpsContext.Provider value={hierarchyTreeCrudOps}>
                <Paper className={classes.page}>
                    <div className={classes.header}>
                        <HierarchyTreeChooser treeInfos={trees}
                                              selectedTree={selectedRootTree}
                                              onTreeSelect={selectRootTree}
                                              canCreateHierarchyTrees={canCreateHierarchyTrees}
                        />
                    </div>
                    <Divider/>
                    {trees.length > 0 &&
                        <div className={classes.body}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <PanelGroup direction={"horizontal"} autoSaveId={"hierarchy-tree-page-panels"}>
                                        <Panel defaultSize={25}
                                               minSize={10}
                                               order={1}>
                                            <HierarchyTree hierarchyTree={selectedRootTree}
                                                           onTreeItemLeafClicked={item => onTreeItemLeafClicked(item)}
                                                           hierarchyTreeController={hierarchyTreeController.current}
                                            />
                                        </Panel>
                                        <PanelResizeHandle className={classes.resizeHandle}>
                                            <Divider orientation={"vertical"} />
                                        </PanelResizeHandle>
                                        <Panel defaultSize={75}
                                               minSize={20}
                                               order={2}>
                                            {selectedDiagramId &&
                                                <DiagramDetailPanel id={selectedDiagramId}
                                                                    controller={diagramController}
                                                                    showHeader={true}
                                                />
                                            }
                                            {selectedElementId &&
                                                <ElementDetailPanel id={selectedElementId}
                                                                    onShowElementDetail={showElementDetail}
                                                                    showHeader={true}
                                                                    onUpdated={() => {}}
                                                />
                                            }
                                        </Panel>
                                    </PanelGroup>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    {trees.length === 0 &&
                        <div className={clsx(classes.body, classes.emptyMessage)}>
                            {_transl(ExtGridTranslationKey.NO_ROWS)}
                        </div>
                    }
                </Paper>
            </HierarchyTreeCrudOpsContext.Provider>
        </>
    )
}
