import React, {useContext, useState} from "react";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../../../components/grid/GridAction";
import DeleteIcon from "@mui/icons-material/Delete";
import Api from "../../../../../../common/Api";
import ConfirmationDialog from "../../../../../../components/dialogs/ConfirmationDialog";
import {PersistentStateId} from "../../../../../../store/common/Grid";
import {RelationshipDto} from "../../../../../../common/apis/relationship/RelationshipDto";
import {RelationshipSearchType} from "../../../../../../common/apis/relationship/RelationshipSearchType";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../../ElementDetailTranslationKey";
import {createRelationshipsPanelColDef} from "./RelationsPanelColumnDefinition";
import ExtGridWrapper from "../../../../../../components/grid/ExtGridWrapper";
import {formatCommonElementName} from "../../../elements/CommonElementNameFormatter";
import {CreateRelationshipDialog} from "./CreateRelationshipDialog";
import {ElementDto} from "../../../../../../common/apis/element/ElementDto";
import {GridEventType} from "../../../../../../components/grid/GridEvents";
import EventManager from "../../../../../../common/event/EventManager";
import EventManagerContext from "../../../../../../common/event/EventManagerContext";
import Snackbar from "../../../snackbar/Snackbar";
import AddIcon from "@mui/icons-material/Add";


interface RelationshipsPanelProps {
    element: ElementDto;
    onPropertyUpdate: () => void;
    relationships: Array<RelationshipDto>;
    disableRelationshipActions?: boolean;
}

export default function RelationshipsPanel(props: RelationshipsPanelProps) {
    const eventManager = useContext<EventManager>(EventManagerContext);

    const {element, onPropertyUpdate, relationships, disableRelationshipActions} = props;
    const [createRelationshipDialog, setCreateRelationshipDialog] = useState<boolean>(false);
    const [deleteRelationshipsDialog, setDeleteRelationshipsDialog] = useState<boolean>(false);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

    const rows = createTableRows(relationships);

    function openDeleteRelationshipsDialog(selectedRowIds: any[]) {
        setSelectedRowIds(selectedRowIds);
        setDeleteRelationshipsDialog(true);
    }

    function deleteSelectedRows() {
        setDeleteRelationshipsDialog(false);
        Api.relationships.deleteRelationships(selectedRowIds)
            .subscribe({
                next: () => {
                    Snackbar.success(_transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_SUCCEEDED));
                    onPropertyUpdate();
                    eventManager.publishEvent({
                        type: GridEventType.UPDATE_SELECTED_ROWS_IDS,
                        selectedRowIds: [],
                    });
                },
                error: () => {
                    Snackbar.error(_transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_FAILED));
                    onPropertyUpdate();
                }
            });
    }

    function getDirection(relation: RelationshipDto) {
        if (relation.source.identifier === relation.target.identifier) {
            return RelationshipSearchType.RECURSIVE;
        } else if (relation.source.identifier === element.identifier) {
            return RelationshipSearchType.SOURCE;
        } else return RelationshipSearchType.TARGET;
    }

    function getOppositeElementName(relation: RelationshipDto) {
        return relation.source.identifier === element.identifier ? formatCommonElementName(relation.target) : formatCommonElementName(relation.source);
    }

    function createTableRows(relationships: RelationshipDto[]) {
        return relationships.map(rel => ({
            identifier: rel.identifier,
            direction: getDirection(rel),
            type: rel.type,
            name: rel.name,
            description: rel.description,
            oppositeElementName: getOppositeElementName(rel),
            acl: rel.acl,
        }));
    }

    return (
        <>
            {createRelationshipDialog &&
                <CreateRelationshipDialog originalElement={element}
                                          onSave={onPropertyUpdate}
                                          onClosed={() => setCreateRelationshipDialog(false)}/>
            }
            {deleteRelationshipsDialog &&
                <ConfirmationDialog open={true}
                                    title={_transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_TITLE)}
                                    confirmationText={_transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_CONFIRMATION)}
                                    onConfirm={() => deleteSelectedRows()}
                                    onReject={() => setDeleteRelationshipsDialog(false)}/>
            }
            <ExtGridWrapper
                columns={createRelationshipsPanelColDef(_transl)}
                rows={rows}
                rowCount={rows.length}
                getRowId={row => row.identifier}
                hideActionButtonsPanel={disableRelationshipActions}
                actions={[
                    GridAction.buttonBuilder("ITEMS_RELATIONSHIP_ADD_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.RELATIONSHIPS_ADD_BUTTON),
                        <AddIcon/>)
                        .onClick(() => setCreateRelationshipDialog(true))
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .build(),
                    GridAction.buttonBuilder("ITEMS_RELATIONSHIP_REMOVE_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_BUTTON),
                        <DeleteIcon/>)
                        .onClick(selectedRowIds => openDeleteRelationshipsDialog(selectedRowIds))
                        .enabledPolicy(EnabledPolicy.WHEN_AT_LEAST_ONE_SELECTED)
                        .isEnabled(selectedRows => selectedRows.every(relationship => relationship.acl.canDelete))
                        .build(),

                ]}
                peristentStateId={PersistentStateId.ELEMENT_DETAIL_PAGE_RELATIONSHIPS_GRID}
                resourceId={element.identifier}/>
        </>
    );
}
