import React, {useEffect, useState} from "react";
import {DataTypeSubformProps} from "../DataTypeSubformProps";
import Grid from "../../../../../../../../components/dialogs/Grid";
import {GraphQueryTreeLevelDefinition, QueryLevel} from "./QueryLevel";
import {AddCircle} from "@mui/icons-material";
import {GraphQueryTreeDataDto, HierarchyTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import {TooltippedIconButton} from "../../../../../../../../components/button/TooltippedIconButton";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";

const MAXIMUM_NUMBER_OF_LEVELS = 5;

export default function QueriesSubform({onChange, treeId, data, validationResult}: DataTypeSubformProps) {

    const [queryLevelDefinitions, setQueryLevelDefinitions] = useState<GraphQueryTreeLevelDefinition[]>(getInitialLevelDefinitions(data));

    function getInitialLevelDefinitions(treeData: HierarchyTreeDataDto): GraphQueryTreeLevelDefinition[] {
        if ((treeData as GraphQueryTreeDataDto).queries.length === 0) {
            return [{
                name: "",
                queryText: "",
            }];
        }

        return (treeData as GraphQueryTreeDataDto).queries.map(levelQueryDto => (
            {
                name: levelQueryDto.name,
                queryText: levelQueryDto.queryText,
                jumpIndex: levelQueryDto.jumpIndex
            }
        ));
    }

    useEffect(() => {
        setQueryLevelDefinitions(getInitialLevelDefinitions(data));
    }, [treeId, data]);

    function handleAddNewLevel() {
        setQueryLevelDefinitions([...queryLevelDefinitions, {name: "", queryText: ""}]);
    }

    function handleLevelRemoved(levelIndex: number) {
        if (levelIndex !== 0) {
            const newQueryLevelDefinitions = queryLevelDefinitions
                .filter((queryLevel, index) => index !== levelIndex);

            const newData = {...data as GraphQueryTreeDataDto, queries: newQueryLevelDefinitions};
            onChange(newData);
            setQueryLevelDefinitions(newQueryLevelDefinitions);
        }
    }

    function handleLevelUpdated(levelIndex: number, levelDefinition: GraphQueryTreeLevelDefinition) {
        const newQueryLevelDefinitions = [...queryLevelDefinitions];
        newQueryLevelDefinitions[levelIndex] = levelDefinition;

        const newData = {...data as GraphQueryTreeDataDto, queries: newQueryLevelDefinitions};
        onChange(newData);
        setQueryLevelDefinitions(newQueryLevelDefinitions);
    }

    return (
        <>
            <Grid item xs={12}>
                {queryLevelDefinitions.map((queryLevelDefinition, index) => (
                    <QueryLevel key={"QueryLevel" + index}
                                levelDefinition={queryLevelDefinition}
                                levelIndex={index}
                                onLevelRemoved={handleLevelRemoved}
                                onLevelUpdated={handleLevelUpdated}
                                validationResult={validationResult}
                                numberOfLevels={queryLevelDefinitions.length}
                    />
                ))}
            </Grid>

            {queryLevelDefinitions.length < MAXIMUM_NUMBER_OF_LEVELS &&
                <Grid item xs={12}>
                    <TooltippedIconButton tooltip={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_ADD)}
                                          icon={<AddCircle sx={{fontSize: "40px"}}/>}
                                          color={"primary"}
                                          size={"small"}
                                          onClick={handleAddNewLevel}
                    />
                </Grid>
            }
        </>
    );
}
