import {DiagramBackup} from "./DiagramBackup";
import {DiagramBackupPersister} from "./DiagramBackupPersister";
import {DiagramBackupInfo} from "./DiagramBackupInfo";
import {Diagram} from "../model/Model";
import {DiagramService} from "../../../pages/main/content/diagrams/service/DiagramService";

export interface DiagramBackupService {
    countBackups(): number;

    findBackup(diagramId: string): DiagramBackup | null;

    findUserBackups(login: string): Promise<Array<DiagramBackupInfo>>;

    doBackup(backup: DiagramBackup): void,

    removeBackup(diagramId: string): void,

    updateBackupDate(diagramId: string, date: Date): void,
}

export class DefaultDiagramBackupService implements DiagramBackupService {

    private persister: DiagramBackupPersister;
    private diagramService: DiagramService;

    constructor(persister: DiagramBackupPersister, diagramService: DiagramService) {
        this.persister = persister;
        this.diagramService = diagramService;
    }

    countBackups(): number {
        return this.persister.load().size;
    }

    findBackup(diagramId: string): DiagramBackup | null {
        return this.persister.load().get(diagramId) || null;
    }

    updateBackupDate(diagramId: string, date: Date) {
        let backup = this.persister.load().get(diagramId);
        if (backup) {
            backup.setDate(date);
            this.persister.persist(backup);
        }
    }

    async findUserBackups(login: string): Promise<Array<DiagramBackupInfo>> {
        const backups = this.persister.load();
        const userBackups = Array.from(backups.values())
            .filter(backup => backup.getLogin() === login);

        const existingBackups: Array<DiagramBackupInfo> = [];
        for (const backup of userBackups) {
            const diagramExists = await this.diagramService.findDiagramById(backup.getDiagramId())
                .catch(error => {
                    if (error.status === 404) {
                        return undefined;
                    }
                    throw error;
                });
            if (!diagramExists) {
                this.persister.remove(backup);
            } else {
                existingBackups.push(this.constructDiagramBackupInfo(backup));
            }
        }
        return existingBackups;
    }

    private constructDiagramBackupInfo(backup: DiagramBackup) {
        const diagramId = backup.getDiagramId();
        const diagram = this.findDiagramById(diagramId, backup.getDiagramModel().diagrams);
        if (!diagram) {
            throw new Error(`Corrupted data received. Cannot find diagram by id: ${diagramId}`);
        }

        return new DiagramBackupInfo(diagramId, diagram.diagramInfo.name || diagramId, this.getDate(backup.getDate()), backup.getLogin());
    }

    getDate(date: Date | string): Date {
        return typeof date === 'string' ? new Date(date) : date;
    }

    doBackup(backup: DiagramBackup): void {
        if (!this.findDiagramById(backup.getDiagramId(), backup.getDiagramModel().diagrams)) {
            throw new Error(`Cannot find diagram by id: ${backup.getDiagramId()}`);
        }
        this.persister.persist(backup);
    }

    removeBackup(diagramId: string): void {
        const backup = this.findBackup(diagramId);
        if (backup) {
            this.persister.remove(backup);
        }
    }

    private findDiagramById(diagramId: string, diagrams: Array<Diagram>): Diagram | null {
        return diagrams
            .find(diagram => diagram.diagramInfo.identifier === diagramId) || null
    }

}
