import React, {useCallback, useEffect, useRef, useState} from "react";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";
import activityService, {ActivityService} from "./ActivityService";
import {ActivityDto} from "../../../../common/apis/activity/ActivityDto";
import {PersistentStateId} from "../../../../store/common/Grid";
import {GridSortModel} from "@mui/x-data-grid-pro";
import {convertToSortingCriterionDto, SortingCriterionDto} from "../../../../common/apis/SortingCriterionDto";
import {GridColDef} from "@mui/x-data-grid";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ActivityTranslationKey} from "./ActivityTranslationKey";
import {ActivityFilterDto} from "../../../../common/apis/activity/ActivityFilterDto";


interface ActivitiesGridProps {
    columns: GridColDef[],
    filter: ActivityFilterDto,
}

export default function ActivitiesGrid(props: ActivitiesGridProps) {

    const columns = props.columns;
    const filter = props.filter;

    const service = useRef<ActivityService>(activityService());

    const [rows, setRows] = useState<ActivityDto[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageToken, setPageToken] = useState<string>();
    const [sortingCriteria, setSortingCriteria] = useState<SortingCriterionDto[]>([]);

    const fetchData = useCallback((pageToken: string | undefined, sortingCriteria: SortingCriterionDto[] | undefined) => {
        service.current.searchByFilter({
            entityIdent: filter.entityIdent,
            entityRefIdent: filter.entityRefIdent,
            validFrom: filter.validFrom,
            validThru: filter.validThru,
            activityTypeCategories: filter.activityTypeCategories,
            maxPageSize: 20,
            pageToken: pageToken,
            sortingCriteria: sortingCriteria
        })
            .then((response) => {
                if (pageToken) {
                    setRows((prevRows) => [...prevRows, ...response.items as Array<ActivityDto>]);
                } else {
                    setRows(response.items as Array<ActivityDto>);
                }

                setTotalCount(response.totalCount);
                setPageToken(response.nextPageToken);
            });
    }, [service, filter]);

    useEffect(() => {
        fetchData(undefined, sortingCriteria);
    }, [fetchData, sortingCriteria]);

    function handleOnRowScrollEnd() {
        if (pageToken) {
            fetchData(pageToken, sortingCriteria);
        }
    }

    function handleOnSortModelChange(gridSortModel: GridSortModel) {
        const newSortingCriteria = createSortingCriteria(gridSortModel);

        fetchData(undefined, newSortingCriteria);
        setSortingCriteria(newSortingCriteria);
    }

    function createSortingCriteria(gridSortModel: GridSortModel) {
        return gridSortModel.map((gridSortItem) => convertToSortingCriterionDto(gridSortItem));
    }

    return (
        <ExtGridWrapper columns={columns}
                        rows={rows}
                        rowCount={totalCount}
                        getRowId={activity => activity.idActivity}
                        hideActionButtonsPanel={false}
                        disableMultipleSelection={true}
                        resourceId={JSON.stringify(filter || {})}
                        peristentStateId={PersistentStateId.ACTIVITIES_PAGE_GRID}
                        treeData={true}
                        getTreeDataPath={activity => activity.path}
                        onRowScrollEnd={handleOnRowScrollEnd}
                        onSortModelChange={handleOnSortModelChange}
                        sortingMode={"server"}
                        groupingColDef={{
                            headerName: _transl(ActivityTranslationKey.TYPE),
                            width: 300,
                            valueGetter: params => _transl(params.row.activityTypeLangIdent)
                        }}
        />
    )
}