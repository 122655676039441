import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import {Badge, Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import RelationshipsPanel from "./side/relationshipspanel/RelationshipsPanel";
import PropertyPanel from "./side/propertiespanel/PropertyPanel";
import {useDispatch, useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {getUiComponentsStateUpdateAction, UiComponentsStateType} from "../../../../store/common/UiComponentsState";
import GraphDataChartPanel from "../../../../components/graphdatachart/GraphDataChartPanel";
import Api from "../../../../common/Api";
import DiagramsElementChartListDialog from "../../../../components/graphdatachart/DiagramsElementChartListDialog";
import {RelationshipDto} from "../../../../common/apis/relationship/RelationshipDto";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "./ElementDetailTranslationKey";
import DiagramsPanel from "./side/diagramspanel/DiagramsPanel";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import {ElementDetailController} from "./controller/ElementDetailController";
import diagramService from "../diagrams/service/DiagramService";
import {DiagramContentFilterDtoBuilder} from "../diagrams/service/DiagramContentFilterDto";
import TrackerTasksPanel from "./tracker/TrackerTasksPanel";
import ActivitiesGrid from "../activity/ActivitiesGrid";
import {ActivityTranslationKey} from "../activity/ActivityTranslationKey";
import {ActivityGridColDefFactory} from "../activity/ActivityGridColDefFactory";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageDiv: {
            display: "flex",
            justifyContent: "center",
        },
        tabs: {
            "&> span:first-child": {
                paddingTop: 9,
            },
            paddingTop: 12,
        },
    })
);

// STATE

enum FetchStatus {
    IN_PROGRESS = "IN_PROGRESS",
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
}

interface State {
    relationshipFetch: {
        fetchStatus: FetchStatus,
        relationships: Array<RelationshipDto>,
        lastUpdateDate: Date,
    },
    relationshipsRefreshTime: number,
    elementsGridIsOpen: boolean,
    elementDtos: Array<ElementDto>,
}

const initialState: State = {
    relationshipFetch: {
        fetchStatus: FetchStatus.IN_PROGRESS,
        relationships: [],
        lastUpdateDate: new Date(),
    },
    relationshipsRefreshTime: new Date().getTime(),
    elementsGridIsOpen: false,
    elementDtos: [],

}

enum ActionType {
    UPDATE_ELEMENT_GRID_IS_OPEN = "UPDATE_ELEMENT_GRID_IS_OPEN",
    UPDATE_ELEMENT_DTOS = "UPDATE_ELEMENT_DTOS",
    UPDATE_RELATIONSHIPS = "UPDATE_RELATIONSHIPS",
    UPDATE_RELATIONSHIPS_REFRESH_TIME = "UPDATE_RELATIONSHIPS_REFRESH_TIME",
}

export type Action =
    { type: ActionType.UPDATE_RELATIONSHIPS, payload: { fetchStatus: FetchStatus, relationships: Array<RelationshipDto> } }
    |
    { type: ActionType.UPDATE_ELEMENT_GRID_IS_OPEN, payload: boolean }
    |
    { type: ActionType.UPDATE_ELEMENT_DTOS, payload: Array<ElementDto> }
    |
    { type: ActionType.UPDATE_RELATIONSHIPS_REFRESH_TIME }

function createUpdateRelationshipsAction(fetchStatus: FetchStatus, relationships: Array<RelationshipDto>): Action {
    return {
        type: ActionType.UPDATE_RELATIONSHIPS,
        payload: {
            fetchStatus: fetchStatus,
            relationships: relationships
        }
    }
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.UPDATE_RELATIONSHIPS:
            return {
                ...state,
                relationshipFetch: {
                    fetchStatus: action.payload.fetchStatus,
                    relationships: action.payload.relationships,
                    lastUpdateDate: new Date(),
                }
            }
        case ActionType.UPDATE_ELEMENT_GRID_IS_OPEN:
            return {
                ...state,
                elementsGridIsOpen: action.payload,
            }
        case ActionType.UPDATE_ELEMENT_DTOS:
            return {
                ...state,
                elementDtos: action.payload,
                elementsGridIsOpen: true,
            }
        case ActionType.UPDATE_RELATIONSHIPS_REFRESH_TIME:
            return {
                ...state,
                relationshipsRefreshTime: new Date().getTime(),
            }
    }
    return state;
}

// PROPS

enum TabId {
    ATTRIBUTES = "ATTRIBUTES",
    DIAGRAMS = "DIAGRAMS",
    GRAPH = "GRAPH",
    RELATIONSHIPS = "RELATIONSHIPS",
    TRACKER_TASKS = "TRACKER_TASKS",
    ACTIVITIES = "ACTIVITIES",
}

interface SidePanelProps {
    element: ElementDto;
    onPropertyUpdate: () => void;
    onShowElementDetail: (elementId: string) => void;
    controller: ElementDetailController;
    disableRelationshipActions? : boolean;
}


export default function SidePanel(props: SidePanelProps) {

    const classes = useStyles();
    const {element, onPropertyUpdate, onShowElementDetail, controller, disableRelationshipActions} = props;
    const elementId = element.identifier;

    // STORE
    const reduxDispatch = useDispatch();
    const selectedTab = useSelector((state: IApplicationState) => state.uiComponentsState[UiComponentsStateType.ITEM_DETAIL_SIDE_SELECTED_TAB] as TabId || TabId.RELATIONSHIPS);

    const trackerConfig = useSelector((state: IApplicationState) => state.trackerConfig);

    // STATE
    const [{
        relationshipFetch: relationshipsFetch,
        relationshipsRefreshTime,
        elementDtos,
        elementsGridIsOpen
    }, dispatch] = useReducer(reducer, initialState);
    const [diagrams, setDiagrams] = useState<DiagramInfoDto[]>([]);

    // EFFECTS
    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            try {
                const filter = DiagramContentFilterDtoBuilder.builder()
                    .elementIds([elementId])
                    .build();
                const fetchedDiagrams = await diagramService.searchByContent(filter);
                if (!isUnmounted) {
                    setDiagrams(fetchedDiagrams);
                }
            } catch (error) {
                setDiagrams([]);
            }
        })();
        return () => {
            isUnmounted = true;
        }
    }, [elementId]);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            try {
                const relationships = await controller.getAllRelationships(elementId);
                if (!isUnmounted) {
                    dispatch(createUpdateRelationshipsAction(FetchStatus.SUCCESSFUL, relationships));
                }
            } catch (error) {
                dispatch(createUpdateRelationshipsAction(FetchStatus.FAILED, []));
            }
        })();
        return () => {
            isUnmounted = true;
        }
    }, [elementId, relationshipsRefreshTime, controller]);


    const onPropertyUpdateClbk = useCallback(() => {
        dispatch({type: ActionType.UPDATE_RELATIONSHIPS_REFRESH_TIME});
        onPropertyUpdate();
    }, [onPropertyUpdate]);


    function getElementSearchDtos(elementDtoIds: Array<string>) {
        Api.elements.doSearch({identifiers: elementDtoIds}).subscribe({
            next: (response) => dispatch({
                type: ActionType.UPDATE_ELEMENT_DTOS,
                payload: response.response.items as Array<ElementDto>
            }),
            error: () => {
            }
        });
    }

    function showElementsGrid(show: boolean) {
        dispatch({type: ActionType.UPDATE_ELEMENT_GRID_IS_OPEN, payload: show});
    }

    return (
        <React.Fragment>
            {elementsGridIsOpen && <DiagramsElementChartListDialog isOpened={elementsGridIsOpen}
                                                                   elementDtos={elementDtos}
                                                                   onDialogClosed={() => showElementsGrid(false)}/>
            }
            <TabContext value={selectedTab}>
                <Tabs
                    value={selectedTab}
                    onChange={(e, val) => reduxDispatch(getUiComponentsStateUpdateAction(UiComponentsStateType.ITEM_DETAIL_SIDE_SELECTED_TAB, val))}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab value={TabId.RELATIONSHIPS} className={classes.tabs}
                         label={
                             <Badge badgeContent={<span>{relationshipsFetch.relationships.length}</span>}
                                    color={"secondary"}>
                                 {_transl(ElementDetailTranslationKey.RELATIONSHIPS)}
                             </Badge>
                         }/>
                    <Tab value={TabId.ATTRIBUTES} className={classes.tabs}
                         label={<span>{_transl(ElementDetailTranslationKey.ATTRIBUTES)}</span>}/>
                    <Tab value={TabId.DIAGRAMS} className={classes.tabs} label={
                        <Badge badgeContent={<span>{diagrams.length}</span>} color={"secondary"}>
                            {_transl(ElementDetailTranslationKey.DIAGRAMS)}
                        </Badge>
                    }/>
                    <Tab value={TabId.GRAPH} className={classes.tabs}
                         label={<span>{_transl(ElementDetailTranslationKey.GRAPH)}</span>}/>
                    {trackerConfig.trackerEnabled &&
                        <Tab value={TabId.TRACKER_TASKS} className={classes.tabs}
                             label={<span>{_transl(ElementDetailTranslationKey.TASKS_TAB_TITLE)}</span>}/>
                    }
                    <Tab value={TabId.ACTIVITIES} className={classes.tabs}
                         label={<span>{_transl(ActivityTranslationKey.ACTIVITY_TITLE)}</span>}/>
                </Tabs>
                <TabPanel value={TabId.RELATIONSHIPS}>
                    <RelationshipsPanel element={element}
                                        onPropertyUpdate={onPropertyUpdateClbk}
                                        relationships={relationshipsFetch.relationships}
                                        disableRelationshipActions={disableRelationshipActions}
                    />
                </TabPanel>
                <TabPanel value={TabId.DIAGRAMS}>
                    <DiagramsPanel elementId={elementId}
                                   diagrams={diagrams}
                    />
                </TabPanel>
                <TabPanel value={TabId.GRAPH} style={{height: "100%"}}>
                    <GraphDataChartPanel entityId={element.identifier}
                                         initialExplodedElementIds={[element.identifier]}
                                         refreshGraphData={(elementIds: Array<string>) => Api.graphData.getNeighbours(elementIds)}
                                         onShowElementDetail={onShowElementDetail}
                                         backendGraphDataChangedDate={relationshipsFetch.lastUpdateDate}
                                         onShowElementsGrid={(elementDtoIds) => getElementSearchDtos(elementDtoIds)}
                    />
                </TabPanel>
                <TabPanel value={TabId.ATTRIBUTES}>
                    <PropertyPanel element={element} onPropertyUpdate={onPropertyUpdate}/>
                </TabPanel>
                {trackerConfig.trackerEnabled &&
                    <TabPanel value={TabId.TRACKER_TASKS}>
                        <TrackerTasksPanel element={element}
                                           trackerConfig={trackerConfig} />
                    </TabPanel>
                }
                <TabPanel value={TabId.ACTIVITIES}>
                    <ActivitiesGrid columns={ActivityGridColDefFactory.buildForElementDetail(element.identifier)}
                                    filter={{entityIdent: element.identifier, entityRefIdent: element.identifier, validFrom: null, validThru: null}}/>
                </TabPanel>
            </TabContext>
        </React.Fragment>
    );
}
