import {ElementDto} from "../../../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../../CommonTranslation";
import {useState} from "react";
import {formatCommonElementName} from "../../../elements/CommonElementNameFormatter";
import Snackbar from "../../../snackbar/Snackbar";
import Dialog from "../../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../../components/dialogs/DialogTitle";
import ElementsPickDialog from "../../../elements/ElementsPickDialog";
import AlertDialog, {AlertDialogType} from "../../../../../../components/dialogs/AlertDialog";
import {ArchimateRelationship} from "../../../../../../common/archimate/ArchimateRelationship";
import SingleselectComboBox from "../../../../../../components/fields/SingleselectComboBox";
import {TextFieldWithMoreIcon} from "../../../../../../components/TextFieldWithAdornmentIcon";
import DialogContent from "../../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../../components/dialogs/DialogActions";
import {SaveButton} from "../../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../../components/button/CancelButton";
import Grid from "../../../../../../components/dialogs/Grid";
import elementsService from "../../../elements/service/ElementService";
import {ElementDetailCreateRelationshipTranslationKey} from "./ElementDetailCreateRelationshipTranslationKey";
import {CreateRelationshipTranslationKey} from "../../../elements/CreateRelationshipTranslationKey";

enum Direction {
    OUTGOING = "outgoing",
    INCOMING = "incoming"
}

interface RelationshipsCreateDialogProps {
    originalElement: ElementDto;
    onSave: () => void,
    onClosed: () => void,
}

export function CreateRelationshipDialog(props: RelationshipsCreateDialogProps) {
    const {originalElement, onSave, onClosed} = props;

    const [isUnsuccessfullyValidated, setUnsuccessfullyValidated] = useState<boolean>(false);
    const [errorDialogOpened, setErrorDialogOpened] = useState(false);
    const [pickDialogOpened, setPickDialogOpened] = useState(false);
    const [pickedElement, setPickedElement] = useState<ElementDto | undefined>(undefined);
    const [direction, setDirection] = useState<string | undefined>(undefined);
    const [type, setType] = useState<string | undefined>(undefined);
    const [directionError, setDirectionError] = useState<string | undefined>(undefined);
    const [typeError, setTypeError] = useState<string | undefined>(undefined);
    const [pickedElementError, setPickedElementError] = useState<string | undefined>(undefined);
    const formattedCommonElementName = formatCommonElementName(pickedElement);

    function onElementsPicked(elements: ElementDto[]) {
        const element = elements[0];
        if (!element.acl.canUpdate && !originalElement.acl.canUpdate) {
            setErrorDialogOpened(true)
        } else {
            isUnsuccessfullyValidated && validatePickedElement(element);
            setPickedElement(element)
            setPickDialogOpened(false);
        }
    }

    const validateDirection = (direction: string | undefined): boolean => {
        if (direction === undefined) {
            setDirectionError(_transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST));
            return false;
        } else {
            setDirectionError(undefined);
            return true;
        }
    };

    const validateType = (type: string | undefined): boolean => {
        if (type === undefined) {
            setTypeError(_transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST));
            return false;
        } else {
            setTypeError(undefined);
            return true;
        }
    };

    const validatePickedElement = (pickedElement: ElementDto | undefined): boolean => {
        if (pickedElement === undefined) {
            setPickedElementError(_transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST));
            return false;
        } else {
            setPickedElementError(undefined);
            return true;
        }
    };

    const isFormValid = (): boolean => {
        const isDirectionValid = validateDirection(direction);
        const isTypeValid = validateType(type);
        const isPickedElementValid = validatePickedElement(pickedElement);
        return isDirectionValid && isTypeValid && isPickedElementValid;
    };

    function saveChanges() {
        if (isFormValid()) {
            const identifier = pickedElement!.identifier;
            const relationshipDirection = direction === Direction.OUTGOING;

            if (identifier !== undefined) {
                createRelationship(identifier, relationshipDirection);
            }
        } else {
            setUnsuccessfullyValidated(true)
        }
    }

    function createRelationship(identifier: string, relationshipDirection: boolean) {
        elementsService.createRelationship(identifier, [originalElement.identifier], type!, relationshipDirection)
            .then(() => {
                onSave();
                onClosed();
                Snackbar.success(_transl(ElementDetailCreateRelationshipTranslationKey.DIALOG_CREATE_RELATIONSHIP_DIRECTION_SUCCEEDED));
            })
            .catch(() => {
                Snackbar.error(_transl(ElementDetailCreateRelationshipTranslationKey.DIALOG_CREATE_RELATIONSHIP_DIRECTION_NOT_SUCCEEDED));
            });
    }

    function getLabelForDirection(direction: Direction) {
        return (direction === Direction.OUTGOING) ? _transl(ElementDetailCreateRelationshipTranslationKey.PICKED_ELEMENT_IS_SOURCE) : _transl(ElementDetailCreateRelationshipTranslationKey.PICKED_ELEMENT_IS_TARGET);
    }

    return (
        <>
            <Dialog open maxWidth={"md"} onClose={onClosed}>
                <DialogTitle id="scroll-dialog-title" title={"Pridani vazby"} onDialogClosed={onClosed}/>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <SingleselectComboBox id={"direction-field"}
                                                  label={_transl(CreateRelationshipTranslationKey.RELATIONSHIP_DIRECTION)}
                                                  required={true}
                                                  options={[Direction.OUTGOING, Direction.INCOMING]}
                                                  getRenderLabel={(value) => getLabelForDirection(value)}
                                                  handleOnChange={(value) => {
                                                      isUnsuccessfullyValidated && validateDirection(value)
                                                      setDirection(value)
                                                  }}
                                                  errorMessage={directionError}
                                                  size={"small"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SingleselectComboBox id={"type-field"}
                                                  label={_transl(CreateRelationshipTranslationKey.RELATIONSHIP_TYPE)}
                                                  required={true}
                                                  options={ArchimateRelationship.values()}
                                                  getRenderLabel={(value) => value?.visibleName || ""}
                                                  handleOnChange={(value) => {
                                                      isUnsuccessfullyValidated && validateType(value?.standardNames[0])
                                                      setType(value?.standardNames[0])
                                                  }}
                                                  errorMessage={typeError}
                                                  size={"small"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldWithMoreIcon key={formattedCommonElementName}
                                                   id="assignedItem-field"
                                                   required={true}
                                                   value={formattedCommonElementName}
                                                   label={_transl(ElementDetailCreateRelationshipTranslationKey.PICKED_ELEMENT)}
                                                   errorMessage={pickedElementError}
                                                   tooltipTitle={_transl(ElementDetailCreateRelationshipTranslationKey.FIND_ELEMENT)}
                                                   onClick={() => setPickDialogOpened(true)}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton onClick={saveChanges}/>
                    <CancelButton onClick={onClosed}/>
                </DialogActions>
            </Dialog>
            <ElementsPickDialog isOpened={pickDialogOpened}
                                isMultiSelection={false}
                                onElementsPicked={onElementsPicked}
                                onDialogClosed={() => setPickDialogOpened(false)}/>
            <AlertDialog open={errorDialogOpened}
                         title={_transl(CreateRelationshipTranslationKey.NOT_ALLOWED_WARNING_TITLE)}
                         text={_transl(CreateRelationshipTranslationKey.NOT_ALLOWED_WARNING_MESSAGE)}
                         type={AlertDialogType.WARNING}
                         onClose={() => setErrorDialogOpened(false)}/>
        </>
    );
}
