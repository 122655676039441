import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ActivityTranslationKey} from "./ActivityTranslationKey";
import moment from "moment/moment";
import {ElementDetailTranslationKey} from "../elementdetail/ElementDetailTranslationKey";

export class ActivityGridColDefFactory {

    private static dateFormatter = (params: GridValueFormatterParams) => params.value != null ? moment(params.value as Date).format("DD.MM.YYYY") : "";

    private static getTargetObjectValue = (targetObjectId: any, targetObjectName: any) => {
        if (!targetObjectId) return "";
        if (!targetObjectName) return `${targetObjectId}`;
        return `${targetObjectId} (${targetObjectName})`;
    };


    public static buildForElementDetail(elementIdentifier: string): GridColDef[] {
        return [
            {
                field: 'userCreated',
                headerName: _transl(ActivityTranslationKey.USER_CREATED),
                width: 150,
                sortable: false
            },
            {
                field: 'created',
                headerName: _transl(ActivityTranslationKey.CREATED),
                width: 150,
                valueFormatter: ActivityGridColDefFactory.dateFormatter
            },
            {
                field: 'targetObject',
                headerName: _transl(ElementDetailTranslationKey.RELATIONSHIPS_CONNECTED_ELEMENT),
                width: 400,
                sortable: false,
                valueGetter: (params) => {
                    if (elementIdentifier === params.row.targetObjectId) {
                        return ActivityGridColDefFactory.getTargetObjectValue(params.row.objectId, params.row.objectName);
                    } else {
                        return ActivityGridColDefFactory.getTargetObjectValue(params.row.targetObjectId, params.row.targetObjectName);
                    }
                }
            }
        ]
    }

    public static buildForDiagramDetail(): GridColDef[] {
        return [
            {
                field: 'userCreated',
                headerName: _transl(ActivityTranslationKey.USER_CREATED),
                width: 150,
                sortable: false
            },
            {
                field: 'created',
                headerName: _transl(ActivityTranslationKey.CREATED),
                width: 150,
                valueFormatter: ActivityGridColDefFactory.dateFormatter
            }
        ]
    }

    public static buildForAdministration(): GridColDef[] {
        return [
            {
                field: 'userCreated',
                headerName: _transl(ActivityTranslationKey.USER_CREATED),
                width: 150,
                sortable: false
            },
            {
                field: 'created',
                headerName: _transl(ActivityTranslationKey.CREATED),
                width: 150,
                valueFormatter: ActivityGridColDefFactory.dateFormatter
            },
            {
                field: 'objectId',
                headerName: _transl(ActivityTranslationKey.OBJECT_IDENTIFIER),
                width: 300,
                sortable: false
            },
            {
                field: 'objectName',
                headerName: _transl(ActivityTranslationKey.OBJECT_NAME),
                width: 300,
                sortable: false
            },
            {
                field: 'targetObject',
                headerName: _transl(ActivityTranslationKey.TARGET_OBJECT),
                width: 400,
                sortable: false,
                valueGetter: (params) => ActivityGridColDefFactory.getTargetObjectValue(params.row.targetObjectId, params.row.targetObjectName)
            }
        ]
    }

}