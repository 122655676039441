import React, {useContext, useRef, useState} from "react";
import {SaveButton} from "../../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../../components/button/CancelButton";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../../ElementDetailTranslationKey";
import {CommonTranslation} from "../../../CommonTranslation";
import {getLabelForPropertyType, propertyTypes} from "../../../property/PropertyType";
import TextField from "../../../../../../components/fields/textfield/TextField";
import Dialog from "../../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../../components/dialogs/DialogActions";
import Grid from "../../../../../../components/dialogs/Grid";
import constructElementDetailController, {ElementDetailController} from "../../controller/ElementDetailController";
import elementService from "../../service/ElementService";
import diagramService from "../../../diagrams/service/DiagramService";
import Snackbar from "../../../snackbar/Snackbar";
import {LabelsTranslationKey} from "../../../labels/LabelsTranslationKey";
import {CreatePropertyDialogValidator} from "./CreatePropertyDialogValidator";
import {IPropertyDefinition} from "../../../../../../common/apis/CommonTypes";
import SingleselectComboBox from "../../../../../../components/fields/SingleselectComboBox";
import SelectField from "../../../../../../components/fields/SelectField";
import ElementPropertyDefinitionContext, {
    ElementPropertyDefinition
} from "../../../../../../common/ElementPropertyDefinitionContext";
import {ErrorTranslationKey} from "../../../ErrorTranslationKey";
import Constants from "../../../../../../common/Constants";

export interface CreatePropertyDialogProps {
    opened: boolean;
    elementId: string;
    onSave: () => void;
    onClosed: () => void;
    existingPropertyNames: Set<string>;
}


export default function CreatePropertyDialog(props: CreatePropertyDialogProps) {
    const elementDetailController = useRef<ElementDetailController>(constructElementDetailController(elementService, diagramService));
    const validator = useRef<CreatePropertyDialogValidator>(new CreatePropertyDialogValidator());
    const {opened, elementId, onSave, onClosed, existingPropertyNames} = props

    const [propertyDefinitionIdentifier, setPropertyDefinitionIdentifier] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [value, setValue] = useState<string>("");

    const [isValidated, setValidated] = useState<boolean>(false);
    const [nameValidationMsg, setNameValidationMsg] = useState<string | undefined>(undefined);
    const [typeValidationMsg, setTypeValidationMsg] = useState<string | undefined>(undefined);
    const [valueValidationMsg, setValueValidationMsg] = useState<string | undefined>(undefined);

    const elementPropertyDefinitionContext = useContext<ElementPropertyDefinition>(ElementPropertyDefinitionContext);
    const propertyDefinitions = elementPropertyDefinitionContext.propertyDefinitions;

    function onDialogClosed() {
        setName("");
        setNameValidationMsg(undefined);
        setTypeValidationMsg(undefined);
        setValue("");
        setType("");
        onClosed();
    }

    function validateName(name: string): boolean {
        let isValid = validator.current.validateName(name);
        setNameValidationMsg(isValid ? undefined : _transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        if (isValid) {
            if (existingPropertyNames.has(name)) {
                setNameValidationMsg(_transl(ErrorTranslationKey.FAILED_TO_ADD_DUPLICATE_ATTRIBUTE));
                isValid = false;
            } else if (name.trim().toLowerCase() === Constants.STEREOTYPE) {
                setNameValidationMsg(_transl(ErrorTranslationKey.NOT_ALLOWED_ATTRIBUTE_NAME, name));
                isValid = false;
            }
        }
        return isValid;
    }

    function validateValue(text: string): boolean {
        const isValid = validator.current.validateValue(text);
        setValueValidationMsg(isValid ? undefined : _transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        return isValid;
    }

    function validateType(text: string): boolean {
        const isValid = validator.current.validateType(text);
        setTypeValidationMsg(isValid ? undefined : _transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        return isValid;
    }

    function isFormValid() {
        const isNameValid = validateName(name);
        const isTypeValid = validateType(type);
        const isValueValid = validateValue(value);

        return isNameValid && isTypeValid && isValueValid;
    }

    const onDialogSave = () => {
        setValidated(true)

        if (isFormValid()) {
            (async () => {
                try {
                    await elementDetailController.current.createProperty(elementId, name.trim(), type, value, propertyDefinitionIdentifier);
                    elementPropertyDefinitionContext.updatePropertyDefinitions();
                    onSave()
                    onClosed()
                    Snackbar.success(_transl(LabelsTranslationKey.CREATE_PROPERTY_SUCCEEDED));
                } catch (e) {
                    Snackbar.error(_transl(LabelsTranslationKey.CREATE_PROPERTY_FAILED));
                }
            })();
        }
    }

    function updateName(newValue: IPropertyDefinition | string) {
        if (typeof newValue === 'string') {
            setName(newValue);
            setPropertyDefinitionIdentifier(undefined);
        } else {
            const propertyDefinition = newValue as IPropertyDefinition;
            setPropertyDefinitionIdentifier(propertyDefinition.identifier);
            setName(propertyDefinition.name);
            setType(propertyDefinition.type.toLowerCase());
        }
    }

    return (
        <React.Fragment>
            {propertyDefinitions &&
                <Dialog open={opened}
                        onClose={() => onDialogClosed()}
                        fullWidth={true}>

                    <DialogTitle id="scroll-dialog-title"
                                 title={_transl(ElementDetailTranslationKey.ATTRIBUTES_ADD_TITLE)}
                                 onDialogClosed={onClosed}/>

                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <SelectField
                                    id="name-field"
                                    value={name}
                                    label={_transl(CommonTranslation.TITLE)}
                                    freeSolo={true}
                                    required={true}
                                    errorMessage={nameValidationMsg}
                                    options={propertyDefinitions}
                                    onChange={(newValue: IPropertyDefinition | string) => updateName(newValue)}
                                    getOptionLabel={option => (option as IPropertyDefinition).name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SingleselectComboBox id={"type-field"}
                                                      options={propertyTypes.map(propertyType => propertyType.value)}
                                                      selectedValue={type}
                                                      getRenderLabel={propertyTypeValue => getLabelForPropertyType(propertyTypeValue)!}
                                                      label={_transl(ElementDetailTranslationKey.TYPE)}
                                                      handleOnChange={(type) => {
                                                          if (isValidated) {
                                                              validateType(type);
                                                          }
                                                          setType(type)
                                                      }}
                                                      errorMessage={typeValidationMsg}
                                                      required={true}
                                                      disabled={propertyDefinitionIdentifier !== undefined}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="value-field"
                                           label={_transl(ElementDetailTranslationKey.ATTRIBUTES_VALUE)}
                                           value={value}
                                           required={true}
                                           errorMessage={valueValidationMsg}
                                           onChange={(value) => {
                                               if (isValidated) {
                                                   validateValue(value);
                                               }
                                               setValue(value)
                                           }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton onClick={onDialogSave}/>
                        <CancelButton onClick={onClosed} variant={"outlined"}/>
                    </DialogActions>
                </Dialog>}
        </React.Fragment>
    );
}
