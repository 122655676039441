import * as d3 from "d3";
import {ILink, INode} from "./ModelManager";
import EventManager from "./EventManager";
import {INodeEventListener} from "../event/Listener";
import {EventType, INodeEvent} from "../event/Event";

export default class SimulationManager implements INodeEventListener {

    public static readonly FORCE_COLLIDE_RADIUS = 60;

    private eventManager: EventManager;
    private simulation?: d3.Simulation<any, any>;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.eventManager.subscribeNodeListener(this, EventType.NODE_DRAG_START);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_DRAG_END);
    }

    public stopSimulation() {
        if (this.simulation != null) {
            // stop previous simulation
            this.simulation && this.simulation.stop();
        }
    }

    public createSimulation(chartGroup: d3.Selection<any, unknown, null, undefined>,
                            nodes: INode[],
                            links: ILink[],
                            linkElems: d3.Selection<any, ILink, any, unknown>,
                            nodeElems: d3.Selection<any, INode, any, unknown>,
                            graphDataChanged: boolean) {
        this.simulation = d3.forceSimulation(nodes)
            .alpha(graphDataChanged ? 1 : .5)
            .force("link", d3.forceLink(links).id(d => (d as ILink).id).distance(270))
            .force("charge", d3.forceManyBody())
            .force("x", d3.forceX())
            .force("y", d3.forceY())
            .force('collide', d3.forceCollide(d => SimulationManager.FORCE_COLLIDE_RADIUS));

        this.defineTickBehavior(chartGroup, linkElems, nodeElems);
    }

    private defineTickBehavior(chartGroup: d3.Selection<any, unknown, null, undefined>,
                               linkElems: d3.Selection<any, ILink, any, unknown>,
                               nodeElems: d3.Selection<any, INode, any, unknown>,
    ) {
        if (this.simulation) {
            this.simulation.on("tick", () => {
                linkElems.attr("d", d => `M${d.source.x},${d.source.y}A0,0 0 0,1 ${d.target.x},${d.target.y}`);
                nodeElems.attr("transform", d => `translate(${d.x},${d.y})`);
                this.rotateRelationshipsNamesWhenTheyAreUpsideDown(chartGroup);
            });
        }
    }

    private rotateRelationshipsNamesWhenTheyAreUpsideDown(chartGroup: d3.Selection<any, unknown, null, undefined>) {
        chartGroup
            .selectAll("text > textPath")
            .each(function () {
                const textPath = d3.select(this as SVGTextPathElement);
                const parentTextNode = (this as SVGTextPathElement).parentNode as SVGTextElement;
                const data = textPath.datum() as {
                    source: { x: number; y: number };
                    target: { x: number; y: number };
                };
                if (parentTextNode) {
                    const parentText = d3.select(parentTextNode);
                    const source = data.source;
                    const target = data.target;
                    const dx = target.x - source.x;
                    const dy = target.y - source.y;
                    const midX = (source.x + target.x) / 2;
                    const midY = (source.y + target.y) / 2;
                    const angle = Math.atan2(dy, dx) * (180 / Math.PI);
                    const correctedAngle = angle <= 90 && angle >= -90 ? 0 : 180;
                    parentText.attr("transform", `rotate(${correctedAngle}, ${midX}, ${midY})`);
                }
            });
    }

    public getSimulation() {
        return this.simulation;
    }

    public handleNodeEvent(event: INodeEvent): void {
        if (event.type === EventType.NODE_DRAG_START) {
            if (!event.event.active) {
                this.simulation?.alphaTarget(0.3).restart();
            }
        }
        if (event.type === EventType.NODE_DRAG_END) {
            if (!event.event.active) {
                this.simulation?.alphaTarget(0);
            }
        }
    }

}