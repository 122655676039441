import {DataSubformValidator} from "../DataSubformValidator";
import {GraphQueryTreeDataDto, GraphQueryTreeLevelDefinitionDto} from "../../../../service/HierarchyTreeDataDto";
import {ValidationError, ValidationResult} from "../../../../../../../../common/validation/ValidationResult";
import {CommonTranslation} from "../../../../../CommonTranslation";

const NAME_FIELD_ID = "name";
const QUERY_TEXT_FIELD_ID = "queryText";

export class QueriesSubformValidator implements DataSubformValidator {

    static get NAME_FIELD_ID() {
        return NAME_FIELD_ID;
    }

    static get QUERY_TEXT_FIELD_ID() {
        return QUERY_TEXT_FIELD_ID;
    }

    validate(data: GraphQueryTreeDataDto): ValidationResult {
        const errors: ValidationError[] = [];

        data.queries.forEach((queryLevelDef, index) => {
            errors.push(...this.validateQueryLevel(queryLevelDef, index));
        });

        return {
            errors: errors
        };
    }

    public validateQueryLevel(levelDef: GraphQueryTreeLevelDefinitionDto, index: number): ValidationError[] {
        const errors = [];

        if (levelDef.name == null || levelDef.name.trim().length === 0) {
            errors.push({
                fieldId: `${index}.${NAME_FIELD_ID}`,
                errorMessage: CommonTranslation.FILL_OUT_THIS_FIELD
            });
        }

        if ((levelDef.queryText == null || levelDef.queryText.trim().length === 0) && (levelDef.jumpIndex == null)) {
            errors.push({
                fieldId: `${index}.${QUERY_TEXT_FIELD_ID}`,
                errorMessage: CommonTranslation.FILL_OUT_THIS_FIELD
            });
        }

        return errors;
    }
}

