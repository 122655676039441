import * as React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../store/Store";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import {_transl} from "../../store/localization/TranslMessasge";
import stereotypesPageAccessResolver from "../../pages/main/content/stereotypes/StereotypesPageAccessResolver";
import collectionsPageAccessResolver from "../../pages/main/content/collections/CollectionsPageAccessResolver";
import importPageAccessResolver from "../../pages/main/content/import/ImportPageAccessResolver";
import RouteDefinition from "../routedefinition/RouteDefinition";
import Constants from "../Constants";
import DashboardPage from "../../pages/main/content/dashboard/DashboardPage";
import HierarchyTreePage from "../../pages/main/content/tree/HierarchyTreePage";
import ElementsPage from "../../pages/main/content/elements/ElementsPage";
import DiagramsPage from "../../pages/main/content/diagrams/DiagramsPage";
import DiagramEditorPage from "../../pages/main/content/diagrams/DiagramEditorPage";
import {MenuDefinitionTranslationKey} from "../menudefinition/MenuDefinitionTranslationKey";
import ProfilePage from "../../pages/main/content/users/ProfilePage";
import GraphQueryPage from "../../pages/main/content/graphquery/GraphQueryPage";
import ExportPage from "../../pages/main/content/export/ExportPage";
import ImportPage from "../../pages/main/content/import/ImportPage";
import GenerateMetamodelPage from "../../pages/main/content/metamodel/extraction/GenerateMetamodelPage";
import ModelsComparisonPage from "../../pages/main/content/modelscomparison/ModelsComparisonPage";
import {ValidateModelPage} from "../../pages/main/content/metamodel/validation/ValidateModelPage";
import {ModelDocumentationPage} from "../../pages/main/content/documentation/ModelDocumentationPage";
import UsersPage from "../../pages/main/content/users/UsersPage";
import LabelsPage from "../../pages/main/content/labels/LabelsPage";
import CollectionsPage from "../../pages/main/content/collections/CollectionsPage";
import StereotypesPage from "../../pages/main/content/stereotypes/StereotypesPage";
import DashboardAdministrationPage from "../../pages/main/content/dashboard/DashboardAdministrationPage";
import AppInfoPage from "../../pages/main/content/AppInfoPage";
import LoginPage from "../../pages/login/LoginPage";
import loginPageService from "../../pages/login/LoginPageService";
import LoginFailedPage from "../../pages/login/LoginFailedPage";
import DiagramExportViewPage from "../../pages/DiagramExportViewPage";
import MainPage from "../../pages/MainPage";
import ActivitiesPage from "../../pages/main/content/activity/ActivitiesPage";


export const ID_PARAM = "id";
export const INITIAL_CONFIGURATION_ID_PARAM = "initialConfigurationId";

export const loginPage = new RouteDefinition(Constants.FE_APP_LOGIN_ROUTE, <LoginPage
    service={loginPageService}/>);
export const loginFailedPage = new RouteDefinition(Constants.FE_APP_LOGIN_FAILED_ROUTE, <LoginFailedPage/>);
export const diagramViewPage = new RouteDefinition(`${Constants.FE_APP_DIAGRAM_EXPORT_VIEW_ROUTE}`,
    <DiagramExportViewPage/>);
export const mainPage = new RouteDefinition(Constants.FE_APP_MAIN_ROUTE, <MainPage/>);

export const dashboardPage = new RouteDefinition(`${Constants.FE_APP_DASHBOARD_ROUTE}`, <DashboardPage/>);
export const structuresPage = new RouteDefinition(`structures`, <HierarchyTreePage/>);
export const elementsPage = new RouteDefinition(`elements`, <ElementsPage/>);
export const diagramsPage = new RouteDefinition(`diagrams`, <DiagramsPage/>);
export const diagramEditorPage = new RouteDefinition(`diagramEditor`, <DiagramEditorPage/>);
export const toolsPage = new RouteDefinition(`tools`, <div>{_transl(MenuDefinitionTranslationKey.TOOLS)}</div>);
export const administrationPage = new RouteDefinition(`administration`,
    <div>{_transl(MenuDefinitionTranslationKey.ADMINISTRATION)}</div>);
export const profilePage = new RouteDefinition(`profile`, <ProfilePage/>);

export const queriesPage = new RouteDefinition(`queries`, <GraphQueryPage/>);
export const exportPage = new RouteDefinition(`export`, <ExportPage/>);
export const exportPageWithPreselectedConfiguration = new RouteDefinition(`export/:${INITIAL_CONFIGURATION_ID_PARAM}`,
    <ExportPage/>);
export const importPage = new RouteDefinition(`import`, <ImportPage/>);
export const importPageWithPreselectedConfiguration = new RouteDefinition(`import/:${INITIAL_CONFIGURATION_ID_PARAM}`,
    <ImportPage/>);
export const generateMetamodelPage = new RouteDefinition(`generateMetamodel`, <GenerateMetamodelPage/>);
export const modelsComparisonPage = new RouteDefinition(`modelsComparison`, <ModelsComparisonPage/>);
export const validateModelPage = new RouteDefinition(`validateModel`, <ValidateModelPage/>);
export const activitiesPage = new RouteDefinition(`activities`, <ActivitiesPage/>);
export const modelDocumentationPage = new RouteDefinition(`modelDocumentation`, <ModelDocumentationPage/>);

export const TOOL_PAGES = [queriesPage, exportPage, importPage, generateMetamodelPage, modelsComparisonPage, validateModelPage, modelDocumentationPage];

export const usersPage = new RouteDefinition(`users`, <UsersPage/>);
export const labelsPage = new RouteDefinition(`labels`, <LabelsPage/>);
export const collectionsPage = new RouteDefinition(`collection`, <CollectionsPage/>);
export const stereotypesPage = new RouteDefinition(`stereotypes`, <StereotypesPage/>);
export const dashboardAdministrationPage = new RouteDefinition(`dashboard-administration`,
    <DashboardAdministrationPage/>);

export const ADMINISTRATION_PAGES = [usersPage, labelsPage, collectionsPage, stereotypesPage, dashboardAdministrationPage];

export const appInfoPage = new RouteDefinition(`about`, <AppInfoPage/>);
export const logoutPage = new RouteDefinition(`dashboard`);


export default function AppRoutes() {
    const user = useSelector((application: IApplicationState) => application.user.userData);
    const dashboard = useSelector((application: IApplicationState) => application.dashboard.mainWidget);

    const canAccessCollectionPage = collectionsPageAccessResolver.hasPageAccess(user);
    const canAccessStereotypePage = stereotypesPageAccessResolver.hasPageAccess(user);
    const canAccessImportPage = importPageAccessResolver.hasPageAccess(user);
    const dashboardEnabled = dashboard.diagramIdentifier || dashboard.url;

    return (
        <Routes>
            <Route path={loginPage.path}
                   element={loginPage.element} />
            <Route path={loginFailedPage.path}
                   element={loginFailedPage.element} />
            <Route path={diagramViewPage.path}
                   element={diagramViewPage.element} />
            <Route path={diagramViewPage.path}
                   element={diagramViewPage.element} />
            <Route path={mainPage.path}
                   index={true}
                   element={dashboardEnabled ?
                       (<Navigate to={dashboardPage.path}/>) : (<Navigate to={Constants.FE_APP_STRUCTURES_ROUTE}/>)}
            />
            <Route path={mainPage.path}
                   element={mainPage.element}
            >
                <Route path={dashboardPage.path}
                       element={dashboardPage.element} />
                <Route path={structuresPage.path}
                       element={structuresPage.element} />
                <Route path={elementsPage.path}
                       element={elementsPage.element}>
                    <Route path={":id"}
                           element={elementsPage.element}/>
                </Route>
                <Route path={diagramsPage.path}
                       element={diagramsPage.element}>
                    <Route path={":id"}
                           element={diagramsPage.element}/>
                </Route>
                <Route path={diagramEditorPage.path}
                       element={diagramEditorPage.element} />

                <Route path={usersPage.path}
                       element={usersPage.element} />
                <Route path={profilePage.path}
                       element={profilePage.element} />
                <Route path={labelsPage.path}
                       element={labelsPage.element} />
                { canAccessCollectionPage &&
                    <Route path={collectionsPage.path}
                           element={collectionsPage.element} />
                }
                { canAccessStereotypePage &&
                    <Route path={stereotypesPage.path}
                           element={stereotypesPage.element} />
                }
                <Route path={dashboardAdministrationPage.path}
                       element={dashboardAdministrationPage.element} />

                <Route path={queriesPage.path}
                       element={queriesPage.element} />
                <Route path={activitiesPage.path}
                       element={activitiesPage.element} />
                <Route path={exportPage.path}
                       element={exportPage.element} />
                <Route path={exportPageWithPreselectedConfiguration.path}
                       element={exportPageWithPreselectedConfiguration.element} />
                { canAccessImportPage &&
                    <Route path={importPage.path}
                           element={importPage.element} />
                }
                <Route path={importPageWithPreselectedConfiguration.path}
                       element={importPageWithPreselectedConfiguration.element} />
                <Route path={generateMetamodelPage.path}
                       element={generateMetamodelPage.element} />
                <Route path={modelsComparisonPage.path}
                       element={modelsComparisonPage.element} />
                <Route path={validateModelPage.path}
                       element={validateModelPage.element} />
                <Route path={modelDocumentationPage.path}
                       element={modelDocumentationPage.element} />

                <Route path={appInfoPage.path}
                       element={appInfoPage.element} />
                <Route path={logoutPage.path}
                       element={logoutPage.element} />

                <Route path={"*"}
                       element={<div>{_transl(CommonTranslation.PAGE_NOT_FOUND)}</div>} />
            </Route>
            <Route path="*"
                   element={dashboardEnabled ?
                       (<Navigate to={dashboardPage.path}/>) : (<Navigate to={Constants.FE_APP_STRUCTURES_ROUTE}/>)}
            />
        </Routes>
    );
}