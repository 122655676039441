import React, {useState} from "react";
import {UserRoleTranslator} from "../../pages/main/content/users/UserRoleTranslator";
import {UserRoleType} from "../../common/access/UserRoleType";
import {UsersTranslationKey} from "../../pages/main/content/users/UsersTranslationKey";
import {_transl} from "../../store/localization/TranslMessasge";
import TextField from "./textfield/TextField";
import {MenuItem} from "@mui/material";

interface EditableRoleComponentProps {
    id: string;
    userRole: UserRoleType;
    initialValues: UserRoleType[];
    doUpdate: (value: UserRoleType) => Promise<any>;
    onSuccessfulUpdate: (value: UserRoleType) => void;
    readOnly: boolean;
}

export default function EditableRoleComponent(props: EditableRoleComponentProps) {
    const {id, userRole, initialValues, doUpdate, onSuccessfulUpdate, readOnly} = props;
    const [role, setRole] = useState<UserRoleType>(userRole ? userRole : UserRoleType.ROLE_OPERATOR);
    const labelFormatter = (role: unknown) => UserRoleTranslator.get().translateUserRole(role as string) ?? "";

    return (
        <>
            {readOnly &&
                <TextField id={id}
                           label={_transl(UsersTranslationKey.USER_ROLE)}
                           value={labelFormatter(role)}
                           InputProps={{
                               readOnly: true,
                           }}/>
            }
            {!readOnly &&
                <TextField id={id}
                           label={_transl(UsersTranslationKey.USER_ROLE)}
                           select={true}
                           value={(role)}
                           onChange={(event) => {
                               const selectedRole = event as UserRoleType;
                               setRole(selectedRole);
                               doUpdate(selectedRole).then(onSuccessfulUpdate);
                           }}>
                    {initialValues.map((option) => (
                        <MenuItem key={option} value={option}>
                            {labelFormatter(option)}
                        </MenuItem>))}
                </TextField>
            }
        </>
    );
}
