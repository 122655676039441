import {LabelTreeViewMode} from "./LabelTreeViewMode";
import {CollectionTreeViewMode} from "./CollectionTreeViewMode";

export interface HierarchyTreeDataDto {
    type: string,
}

export interface CollectionsTreeDataDto extends HierarchyTreeDataDto {
    viewMode: CollectionTreeViewMode;
    collections: string[];
}

export interface FolderTreeDataDto extends HierarchyTreeDataDto {
    type: "Folder";
    icon?: string;
    color?: string;
}

export interface LabelsTreeDataDto extends HierarchyTreeDataDto {
    type: "Labels";
    viewMode: LabelTreeViewMode;
    labels: string[];
}

export interface LayersTreeDataDto extends HierarchyTreeDataDto {
    type: "Layers";
    collections: string[];
}

export interface GraphQueryTreeDataDto extends HierarchyTreeDataDto {
    type: "GraphQueries";
    queries: GraphQueryTreeLevelDefinitionDto[];
}

export interface GraphQueryTreeLevelDefinitionDto {
    name: string,
    queryText?: string,
    jumpIndex?: number
}

export enum ReferenceType {
    ELEMENT_PAGE_REPORT = "ELEMENT_PAGE_REPORT",
    URL = "URL"
}

export interface ReferenceTreeDataDto extends HierarchyTreeDataDto {
    type: "Reference";
    referenceType: ReferenceType;
    reference: string;
}

export function createCollectionsTreeDataDto(viewMode: CollectionTreeViewMode, collections: string[]): CollectionsTreeDataDto {
    return {type: "Collections", viewMode: viewMode, collections: collections};
}

export function createFolderTreeDataDto(): FolderTreeDataDto {
    return {type: "Folder"};
}

export function createLabelsTreeDataDto(viewMode: LabelTreeViewMode, labels: string[]): LabelsTreeDataDto {
    return {type: "Labels", viewMode: viewMode, labels: labels};
}

export function createLayersTreeDataDto(collections: string[]): LayersTreeDataDto {
    return {type: "Layers", collections: collections};
}

export function createQueriesTreeDataDto(queries: GraphQueryTreeLevelDefinitionDto[]): GraphQueryTreeDataDto {
    return {type: "GraphQueries", queries: queries};
}
