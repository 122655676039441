import React, {useState} from "react";
import {IPropertyDto} from "../../../../common/apis/CommonTypes";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import diagramService from "./service/DiagramService";
import {getLabelForPropertyType} from "../property/PropertyType";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import Grid from "../../../../components/dialogs/Grid";
import Snackbar from "../snackbar/Snackbar";
import TextField from "../../../../components/fields/textfield/TextField";
import {CommonTranslation} from "../CommonTranslation";


interface UpdateDiagramPropertyDialogProps {
    opened: boolean;
    diagramId: string;
    onPropertyChanged: () => void;
    selectedProperty: IPropertyDto;
    onClosed: () => void;
}

export default function UpdateDiagramPropertyDialog(props: UpdateDiagramPropertyDialogProps) {
    const {diagramId, onPropertyChanged, selectedProperty, onClosed} = props

    const identifier = selectedProperty.definition.identifier;
    const name = selectedProperty.definition.name;
    const type = selectedProperty.definition.type;
    const [value, setValue] = useState<string>(selectedProperty.value);
    const [valueValidationMsg, setValueValidationMsg] = useState<string | undefined>(undefined);

    function saveChanges() {
        const isValueValid = validateValue();
        if (isValueValid) {
            diagramService.updateDiagramProperty(diagramId, identifier, value)
                .then(() => {
                    onClosed();
                    onPropertyChanged();
                })
                .catch((err) => {
                    Snackbar.error(_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_FAILED_TO_EDIT), err);
                });
        }
    }

    function validateValue(): boolean {
        const isValid = !(value.trim().length === 0);
        setValueValidationMsg(isValid ? undefined : _transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        return isValid;
    }

    return (
        <Dialog open onClose={onClosed}>
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_DIALOG_TITLE)}
                         onDialogClosed={onClosed}/>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="name-field"
                            label={_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_TITLE)}
                            value={name}
                            disabled={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="type-field"
                            label={_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_TYPE)}
                            value={getLabelForPropertyType(type)}
                            disabled={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="value-field"
                            label={_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_VALUE)}
                            value={value}
                            required={true}
                            errorMessage={valueValidationMsg}
                            onChange={(newValue) => setValue(newValue)}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={(e) => saveChanges()}/>
                <CancelButton onClick={onClosed} variant={"outlined"}/>
            </DialogActions>
        </Dialog>
    );
}
