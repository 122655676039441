import Constants from "../../../../common/Constants";
import Api from "../../../../common/Api";
import {map} from "rxjs/operators";
import {ActivityFilterDto} from "../../../../common/apis/activity/ActivityFilterDto";
import {ActivityDto} from "../../../../common/apis/activity/ActivityDto";
import {ResultListDto} from "../../../../common/apis/ResultListDto";
import {ActivityCategoryDto} from "../../../../common/apis/activity/ActivityCategoryDto";

export interface ActivityService {
    searchByFilter(filter: ActivityFilterDto): Promise<ResultListDto<ActivityDto>>;
    getCategories(): Promise<ActivityCategoryDto[]>;
}

class RxJsActivityService implements ActivityService {

    async searchByFilter(filter: ActivityFilterDto): Promise<ResultListDto<ActivityDto>> {
        const request = {
            url: Constants.API_HOST + "/rest-api/repository/activities:search",
            method: "POST",
            body: filter,
        };

        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ResultListDto<ActivityDto>)
            ).toPromise();
    }

    async getCategories(): Promise<ActivityCategoryDto[]> {
        const request = {
            url: Constants.API_HOST + "/rest-api/repository/activities/categories",
            method: "GET",
        };

        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ActivityCategoryDto[])
            ).toPromise();
    }
}

export default function activityService(): ActivityService {
    return new RxJsActivityService() as ActivityService;
}
