import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import {_transl} from "../../../../store/localization/TranslMessasge";
import DialogContent from "../../../../components/dialogs/DialogContent";
import TextField from "../../../../components/fields/textfield/TextField";
import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../../components/button/Button";
import {
    Checkbox,
    DialogActions,
    FormControlLabel,
    IconButton,
    Tab,
    Tabs,
    Tooltip,
} from "@mui/material";
import {GraphQueryTranslationKey} from "./GraphQueryTranslationKey";
import Snackbar from "../snackbar/Snackbar";
import GraphDataChartPanel from "../../../../components/graphdatachart/GraphDataChartPanel";
import Api from "../../../../common/Api";
import {CustomGraphQueryDto} from "../../../../common/apis/query/CustomGraphQueryDto";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {GraphQueryDto} from "../../../../common/apis/query/GraphQueryDto";
import Grid from "../../../../components/dialogs/Grid";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import graphQueryService from "./GraphQueryService";
import {GraphQueryUpdateDto} from "../../../../common/apis/query/GraphQueryUpdateDto";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";
import ElementDetailDialog from "../elementdetail/ElementDetailDialog";
import DiagramsElementChartListDialog from "../../../../components/graphdatachart/DiagramsElementChartListDialog";
import {map} from "rxjs/operators";
import {TabContext, TabPanel} from "@mui/lab";
import ElementsGrid, {ElementsGridType} from "../elements/ElementsGrid";
import {IFilter} from "../../../../store/elements/Elements";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {ValidationError} from "../../../../common/ValidationError";
import {CommonTranslation} from "../CommonTranslation";
import InfoIcon from "@mui/icons-material/Info";
import {GraphQueryCreateDto} from "../../../../common/apis/query/GraphQueryCreateDto";
import {ExtGridTranslationKey} from "../../../../components/grid/ExtGridTranslationKey";
import {PersistentStateId} from "../../../../store/common/Grid";
import ElementsPickDialog from "../elements/ElementsPickDialog";
import {TextFieldWithMoreIcon} from "../../../../components/TextFieldWithAdornmentIcon";

interface QueryEditorDialogProps {
    onClosed: () => void,
    queryId?: string,
    reloadQueries: () => void,
    isReadonly: boolean,
}

enum TabId {
    ELEMENTS = "ELEMENTS",
    GRAPH = "GRAPH",
}

enum ResultType {
    NONE,
    EMPTY_RESULT,
    ELEMENTS,
    ERROR,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        warningTypography: {
            paddingLeft: theme.spacing(1),
        }
    })
);

const emptyQueryDto: GraphQueryDto = {
    id: "",
    name: "",
    description: "",
    queryText: "",
    isPrivate: false,
    created: "",
    lastUpdated: "",
    acl: {
        canUpdate: true,
        canDelete: true
    }
};

export default function QueryEditorDialog({onClosed, queryId, reloadQueries, isReadonly}: QueryEditorDialogProps) {

    const classes = useStyles();

    const CYPHER_REF_URL = 'https://s3.amazonaws.com/artifacts.opencypher.org/openCypher9.pdf';

    const [query, setQuery] = useState<GraphQueryDto>(emptyQueryDto);
    const [initialQuery, setInitialQuery] = useState<GraphQueryDto>(emptyQueryDto);
    const [elements, setElements] = useState<ElementDto[]>([]);
    const [elementsWithNeighbours, setElementsWithNeighbours] = useState<ElementDto[]>([]);
    const [firstElement, setFirstElement] = useState<ElementDto>();
    const [resultType, setResultType] = useState<ResultType>(ResultType.NONE);
    const [showWarningCloseDialog, setShowWarningCloseDialog] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [showElementsGrid, setShowElementsGrid] = useState<boolean>(false);
    const [elementsPickDialogOpened, setElementsPickDialogOpened] = useState<boolean>(false);
    const [elementIdentifier, setElementIdentifier] = useState<string| undefined>();
    const [selectedTab, setSelectedTab] = useState<TabId>(TabId.ELEMENTS);
    const [elementsGridFilter, setElementsGridFilter] = useState<IFilter | undefined>(undefined);

    const [nameError, setNameError] = useState<string>();
    const [queryTextError, setQueryTextError] = useState<string>();

    const onRunCustomQueryRef = useRef(onRunCustomQuery);
    const queryRef = useRef(query);
    queryRef.current = query;

    const [elementDetailDialogInfo, setElementDetailDialogInfo] = useState<string | undefined>();

    function onRunCustomQuery() {
        if (queryRef.current.queryText !== "") {
            setFirstElement(undefined);
            graphQueryService().runCustomQuery(createCustomGraphQueryDto(queryRef.current.queryText, elementIdentifier))
                .then((queryResult) => {
                    setElements(queryResult);
                    setElementsGridFilter(prevFilter => ({
                        ...prevFilter,
                        identifiers: queryResult.map(dto => dto.identifier)
                    }));
                    if (queryResult.length === 0) {
                        setResultType(ResultType.EMPTY_RESULT);
                    } else {
                        setFirstElement(queryResult[0]);
                        setResultType(ResultType.ELEMENTS);
                    }
                })
                .catch((error) => {
                    setResultType(ResultType.ERROR);
                    if (error instanceof ValidationError) {
                        setErrorMessage(error.error.message);
                    } else {
                        setErrorMessage(undefined);
                        Snackbar.error(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED);
                    }
                });
        }
    }

    useEffect(() => {
        if (query.id) {
            onRunCustomQueryRef.current();
        }
    }, [query.id, onRunCustomQueryRef]);

    useEffect(() => {
        if (queryId) {
            graphQueryService().findById(queryId)
                .then((query) => {
                    setQuery(query);
                    setInitialQuery(query);
                });
        }
    }, [queryId]);

    function getElementsIds(): string[] {
        return elements.map((element) => element.identifier);
    }

    function createCustomGraphQueryDto(queryText: string, elementIdentifier?: string): CustomGraphQueryDto {
        return {
            queryText: queryText,
            evaluationContext: elementIdentifier ? {id: elementIdentifier} : undefined
        };
    }

    function createQuery() {
        if (isInputValid()) {
            graphQueryService().create(createCreateQuery())
                .then((id) => {
                    setQuery({...query, id});
                    setInitialQuery(query);
                    onRunCustomQuery();
                    reloadQueries();
                    Snackbar.success(_transl(GraphQueryTranslationKey.ADD_SUCCEEDED));
                })
                .catch(() => {
                    Snackbar.error(_transl(GraphQueryTranslationKey.ADD_FAILED));
                });
        }
    }

    function updateQuery() {
        if (query.id && isInputValid()) {
            graphQueryService().update(query.id, createUpdateQuery())
                .then(() => {
                    setInitialQuery(query);
                    reloadQueries();
                    Snackbar.success(_transl(GraphQueryTranslationKey.UPDATE_SUCCEEDED));
                })
                .catch(() => {
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA));

                });
        }
    }

    function isInputValid(): boolean {
        const isNameValid = validateName();
        const isQueryTextValid = validateQueryText();
        return isNameValid && isQueryTextValid;
    }

    function validateName(): boolean {
        if (query.name.trim().length === 0) {
            setNameError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            return false;
        } else {
            setNameError(undefined);
            return true;
        }
    }

    function validateQueryText(): boolean {
        if (query.queryText.trim().length === 0) {
            setQueryTextError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            return false;
        } else {
            setQueryTextError(undefined);
            return true;
        }
    }

    function createCreateQuery(): GraphQueryCreateDto {
        return {
            name: query.name,
            queryText: query.queryText,
            description: query.description ?? "",
            isPrivate: query.isPrivate
        }
    }

    function createUpdateQuery(): GraphQueryUpdateDto {
        return {
            name: query.name,
            queryText: query.queryText,
            description: query.description ?? "",
            isPrivate: query.isPrivate,
        }
    }

    function areChangesUnsaved(): boolean {
        return (
            query.name !== initialQuery.name ||
            query.description !== initialQuery.description ||
            query.queryText !== initialQuery.queryText ||
            query.isPrivate !== initialQuery.isPrivate
        );
    }

    function onDialogClosed() {
        if (areChangesUnsaved() && !isReadonly) {
            setShowWarningCloseDialog(true);
        } else {
            onClosed();
        }
    }

    async function getElementSearchDtos(elementDtoIds: Array<string>) {
        let result = await Api.elements.doSearch({identifiers: elementDtoIds}).pipe(
            map(response => response.response.items as ElementDto[])
        ).toPromise();
        setElementsWithNeighbours(result);
        setShowElementsGrid(true);
    }

    return (
        <>
            {showWarningCloseDialog &&
                <ConfirmationDialog open={showWarningCloseDialog}
                                    title={_transl(GraphQueryTranslationKey.UNSAVED_CHANGES)}
                                    confirmationText={_transl(GraphQueryTranslationKey.CONFIRMATION_TEXT)}
                                    onConfirm={() => onClosed()}
                                    onReject={() => setShowWarningCloseDialog(false)}
                                    isModal={true}/>
            }
            {elementDetailDialogInfo &&
                <ElementDetailDialog initialElementId={elementDetailDialogInfo}
                                     opened={true}
                                     onClosed={() => setElementDetailDialogInfo(undefined)}/>
            }
            {showElementsGrid &&
                <DiagramsElementChartListDialog isOpened={true}
                                                elementDtos={elementsWithNeighbours}
                                                onDialogClosed={() => setShowElementsGrid(false)}/>
            }
            {elementsPickDialogOpened &&
                <ElementsPickDialog isOpened={true}
                                    isMultiSelection={false}
                                    onElementsPicked={(elements) => {
                                        setElementIdentifier(elements[0].identifier);
                                        setElementsPickDialogOpened(false);
                                    }}
                                    onDialogClosed={() => setElementsPickDialogOpened(false)}/>
            }
            <Dialog open={true} maxWidth={"lg"}
                    onClose={(event, reason) => {
                        if (reason) {
                            if ((reason === "backdropClick")) {
                                return;
                            } else if ((reason === "escapeKeyDown")) {
                                onDialogClosed();
                            }
                        }
                    }}>
                <DialogTitle id="create-query-dialog-title"
                             title={_transl(GraphQueryTranslationKey.EDITOR_TITLE)}
                             onDialogClosed={() => {
                                 onDialogClosed();
                             }}/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Grid item style={{paddingTop: 0}}>
                                <TextField id="create-query-name-field"
                                           label={_transl(GraphQueryTranslationKey.NAME)}
                                           value={query.name}
                                           onChange={(name) => setQuery({...query, name: name})}
                                           errorMessage={nameError}
                                           required
                                           disabled={isReadonly}/>
                            </Grid>
                            <Grid item>
                                <TextField id={"create-query-description-field"}
                                           label={_transl(GraphQueryTranslationKey.DESCRIPTION)}
                                           value={query.description}
                                           multiline={true}
                                           rows={2}
                                           onChange={(description) => setQuery({...query, description: description})}
                                           disabled={isReadonly}/>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    label={_transl(GraphQueryTranslationKey.IS_PRIVATE)}
                                    control={
                                        <Checkbox
                                            checked={query.isPrivate}
                                            onChange={(e) => setQuery({...query, isPrivate: e.target.checked})
                                            }
                                            color="primary"
                                            disabled={isReadonly}/>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid item style={{paddingTop: 0}}>
                                <TextFieldWithMoreIcon
                                    label={_transl(GraphQueryTranslationKey.REFERENCE_ELEMENT)}
                                    value={elementIdentifier}
                                    onClick={() => setElementsPickDialogOpened(true)}
                                    tooltipTitle={_transl(GraphQueryTranslationKey.TOOLTIP)}
                                    clearable
                                    onClearButtonClick={() => {
                                        setElementsPickDialogOpened(false);
                                        setElementIdentifier("");
                                    }}
                                    required={false}
                                />
                            </Grid>
                            <Grid item>
                                <TextField label={_transl(GraphQueryTranslationKey.QUERY_TEXT)}
                                           value={query.queryText}
                                           multiline={true}
                                           rows={7}
                                           onChange={queryText => setQuery((query) => ({...query, queryText}))}
                                           errorMessage={queryTextError}
                                           required/>
                                <DialogActions>
                                    <div style={{display: 'flex', flexGrow: 1}}>
                                        <Tooltip title={_transl(GraphQueryTranslationKey.CYPHER_REF)}>
                                            <IconButton
                                                onClick={() => window.open(CYPHER_REF_URL, '_blank')}
                                                size="small">
                                                <InfoIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Button
                                        label={_transl(GraphQueryTranslationKey.EDITOR_RUN_QUERY)}
                                        onClick={onRunCustomQuery}
                                        variant={"contained"}
                                        color="primary"
                                        disabled={query.queryText === ""}
                                        icon={<PlayArrowIcon/>}
                                    />
                                    {query.id &&
                                        <Button label={_transl(CommonTranslation.SAVE)}
                                                onClick={() => updateQuery()}
                                                variant={"outlined"}
                                                disabled={!areChangesUnsaved() || isReadonly}
                                                color="primary"/>
                                    }
                                    {!query.id &&
                                        <Button label={_transl(CommonTranslation.CREATE)}
                                                onClick={() => createQuery()}
                                                variant={"outlined"}
                                                color="primary"/>
                                    }
                                </DialogActions>
                            </Grid>
                        </Grid>
                        {resultType === ResultType.ELEMENTS &&
                            <Grid item xs={12}>
                                <TabContext value={selectedTab}>
                                    <Tabs
                                        value={selectedTab}
                                        onChange={(e, val) => setSelectedTab(val)}
                                        indicatorColor="primary"
                                        textColor="primary"
                                    >
                                        <Tab value={TabId.ELEMENTS}
                                             label={<span>{_transl(GraphQueryTranslationKey.ELEMENTS)}</span>}/>
                                        <Tab value={TabId.GRAPH}
                                             label={<span>{_transl(GraphQueryTranslationKey.GRAPH)}</span>}/>

                                    </Tabs>
                                    <TabPanel value={TabId.ELEMENTS}>
                                        <ElementsGrid
                                            filter={elementsGridFilter!}
                                            elementsGridType={ElementsGridType.LISTING}
                                            refetchData={() => {
                                            }}
                                            persistentStateId={PersistentStateId.ELEMENTS_LIST_GRID}
                                        />
                                    </TabPanel>
                                    <TabPanel value={TabId.GRAPH}>
                                        <GraphDataChartPanel entityId={firstElement?.identifier ?? ""}
                                                             initialExplodedElementIds={[firstElement?.identifier ?? ""]}
                                                             refreshGraphData={(elementIds: Array<string>) => Api.graphData.getInterrelationships(getElementsIds())}
                                                             onShowElementDetail={id => setElementDetailDialogInfo(id)}
                                                             backendGraphDataChangedDate={new Date()}
                                                             onShowElementsGrid={(elementDtoIds) => getElementSearchDtos(elementDtoIds)}/>
                                    </TabPanel>

                                </TabContext>
                            </Grid>

                        }
                        {resultType === ResultType.EMPTY_RESULT &&
                            <>
                                <Grid item xs={"auto"}>
                                    <InfoIcon color={"info"}/>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={classes.warningTypography}>
                                        {_transl(ExtGridTranslationKey.NO_RESULTS_OVERLAY_LABEL)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {resultType === ResultType.ERROR &&
                            <>
                                <Grid item xs={"auto"}>
                                    <WarningIcon color={"error"}/>
                                </Grid>
                                <Grid item xs>
                                    <Typography className={classes.warningTypography}>
                                        {_transl(GraphQueryTranslationKey.ERROR_QUERY_EXECUTION_MESSAGE)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField value={errorMessage}
                                               multiline={true}
                                               rows={5}
                                               InputProps={{readOnly: true}}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

