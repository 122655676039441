import {Checkbox, FormControlLabel, FormGroup, IconButton, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {_transl} from "../../../../store/localization/TranslMessasge";
import Divider from "@mui/material/Divider";
import clsx from "clsx";
import Wizard from "../../../../components/wizard/Wizard";
import React, {useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import metamodelDocumentationService, {
    ModelDocumentationPropertiesDto
} from "../../../../common/apis/ModelDocumentationService";
import {useNavigate} from "react-router-dom";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import {WizardStep} from "../../../../components/wizard/WizardStep";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ActionButtonUtils from "../../../../components/grid/ActionButtonUtils";
import AddIcon from "@mui/icons-material/Add";
import DiagramsPickDialog from "../diagrams/DiagramsPickDialog";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../components/grid/GridAction";
import DeleteIcon from "@mui/icons-material/Delete";
import {PersistentStateId} from "../../../../store/common/Grid";
import {GridColDef} from "@mui/x-data-grid";
import {DiagramTranslationKey} from "../diagrams/DiagramTranslationKey";
import {ImportTranslationKey} from "../import/ImportTranslationKey";
import SettingsIcon from "@mui/icons-material/Settings";
import {AjaxResponse} from "rxjs/ajax";
import {BlobUtils} from "../../../../common/BlobUtils";
import {ModelDocumentationTranslationKey} from "./ModelDocumentationTranslationKey";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";
import {GridRowOrderChangeParams} from "@mui/x-data-grid-pro";

class GridDef {
    public static getDiagramsGridColDef(): GridColDef[] {
        return [
            {
                field: 'identifier',
                headerName: _transl(DiagramTranslationKey.GRID_HEADER_IDENTIFIER),
                headerClassName: 'datagrid-column',
                width: 150
            },
            {
                field: 'name',
                headerName: _transl(DiagramTranslationKey.GRID_HEADER_NAME),
                headerClassName: 'datagrid-column',
                minWidth: 280,
                flex: 1
            },
        ];
    }
}

enum ButtonId {
    ADD_ITEMS = "ADD_ITEMS",
    REMOVE_ITEM = "REMOVE_ITEM",
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(7)}),
        wizardDiagramSelectionDescription: {
            display: "flex",
            alignItems: "center",
            gap: "1em",
        },
        itemsGridDiv: {
            color: "gray",
            backgroundColor: "white",
            padding: theme.spacing(1),
        },
        settingsDiv: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
            border: "1px solid lightgray",
            backgroundColor: "white",
            width: "100%",
            height: "100%",
        },
    }));

const DIAGRAMS_GRID_ID = PersistentStateId.EXPORT_MODEL_DOCUMENTATION_GRID;

export function ModelDocumentationPage() {

    const classes = useStyles();
    const navigate = useNavigate();

    const [selectDiagramsDialogOpened, setSelectDiagramsDialogOpened] = useState<boolean>(false);
    const [diagrams, setDiagrams] = useState<Array<DiagramInfoDto>>([]);
    const [includeElementDescription, setIncludeElementDescription] = useState<boolean>(false);
    const [includeDiagramDescription, setIncludeDiagramDescription] = useState<boolean>(false);

    function generateModelDocumentation(): Promise<any> {
        const dto: ModelDocumentationPropertiesDto = {
            diagramIdentifiers: diagrams.map(value => value.identifier),
            includeElementDescription: includeElementDescription,
            includeDiagramDescription: includeDiagramDescription,
        }
        return metamodelDocumentationService.generateModelDocumentation(dto);
    }

    function cancelWizard() {
        navigate(0);
    }

    function canProceedFromSelectDiagramsStep(): boolean {
        return diagrams.length > 0;
    }

    function addItems(items: Array<DiagramInfoDto>, itemsToBeAdded: Array<DiagramInfoDto>): Array<DiagramInfoDto> {
        const newItems = removeItems(itemsToBeAdded, items.map(item => item.identifier));
        return [...items, ...newItems];
    }

    function removeItems(items: Array<DiagramInfoDto>, identifiersToRemove: Array<string>) {
        return items.filter(item => identifiersToRemove.indexOf(item.identifier) === -1);
    }

    function onRowReorderChange(params: GridRowOrderChangeParams) {
        reorderItem(params.row as DiagramInfoDto, params.oldIndex, params.targetIndex);
    }

    function reorderItem(item: DiagramInfoDto, oldIndex: number, targetIndex: number) {
        let reorderedItems = [...diagrams];
        reorderedItems.splice(oldIndex, 1);
        reorderedItems.splice(targetIndex, 0, item);
        setDiagrams(reorderedItems);
    }

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(
                _transl(ModelDocumentationTranslationKey.STEP_DIAGRAMS_SELECTION_LABEL),
                <AccountTreeIcon/>,
                <div className={classes.wizardDiagramSelectionDescription}>
                    <div>{_transl(ModelDocumentationTranslationKey.STEP_DIAGRAMS_SELECTION_DESCRIPTION)}</div>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={() => ActionButtonUtils.clickOnImmediateButton(ButtonId.ADD_ITEMS, DIAGRAMS_GRID_ID)}
                            size="large">
                            <AddIcon/>
                        </IconButton>
                    </div>
                </div>,
                true,
                () => renderSelectDiagramsStep(),
                () => canProceedFromSelectDiagramsStep()),
            new WizardStep(
                _transl(ImportTranslationKey.OTHER_CHOICES),
                <SettingsIcon/>,
                _transl(ImportTranslationKey.OTHER_CHOICES_ICON),
                true,
                () => renderSettingsStep(),
                () => true)
        ];
    }

    function renderSettingsStep(): JSX.Element {
        return <div className={classes.settingsDiv}>
            <div>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={includeElementDescription}
                                onChange={event => setIncludeElementDescription(event.target.checked)}
                                name="includeElementDescription"
                                color="primary"
                            />
                        }
                        label={_transl(ModelDocumentationTranslationKey.OPTION_INCLUDE_ELEMENT_DESCRIPTION)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={includeDiagramDescription}
                                onChange={event => setIncludeDiagramDescription(event.target.checked)}
                                name="includeDiagramDescription"
                                color="primary"
                            />
                        }
                        label={_transl(ModelDocumentationTranslationKey.OPTION_INCLUDE_DIAGRAM_DESCRIPTION)}
                    />
                </FormGroup>
            </div>
        </div>
    }

    function renderSelectDiagramsStep(): JSX.Element {
        return <div className={classes.itemsGridDiv}>
            {selectDiagramsDialogOpened && <DiagramsPickDialog isOpened={selectDiagramsDialogOpened}
                                                               isMultiSelection={true}
                                                               onDiagramsPicked={(pickedDiagrams) => {
                                                                   setDiagrams(addItems(diagrams, pickedDiagrams));
                                                                   setSelectDiagramsDialogOpened(false)
                                                               }}
                                                               onDialogClosed={() => setSelectDiagramsDialogOpened(false)}/>
            }
            <ExtGridWrapper
                columns={GridDef.getDiagramsGridColDef()}
                rows={diagrams}
                rowCount={diagrams.length}
                getRowId={row => row.identifier}
                actions={[
                    GridAction.buttonBuilder(ButtonId.ADD_ITEMS, ActionButtonType.IMMEDIATE, _transl(ModelDocumentationTranslationKey.DIAGRAMS_GRID_BUTTON_ADD),
                        <AddIcon/>)
                        .onClick(() => setSelectDiagramsDialogOpened(true))
                        .enabledPolicy(EnabledPolicy.ALWAYS).build(),
                    GridAction.buttonBuilder(ButtonId.REMOVE_ITEM, ActionButtonType.IMMEDIATE, _transl(ModelDocumentationTranslationKey.DIAGRAMS_GRID_BUTTON_REMOVE),
                        <DeleteIcon/>)
                        .onClick(selectedRowIds => setDiagrams(removeItems(diagrams, selectedRowIds as Array<string>)))
                        .build(),
                ]}
                rowReordering={true}
                onRowReorderChange={onRowReorderChange}
                peristentStateId={DIAGRAMS_GRID_ID}
                resourceId={"diagrams"}
            />
        </div>
    }

    return (
        <Paper className={classes.page}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {_transl(ModelDocumentationTranslationKey.TITLE)}
                </Typography>
            </div>
            <Divider/>
            <div className={clsx(classes.controlPageSegment)}>
                <Wizard steps={getSteps()}
                        lastStepLabel={_transl(ModelDocumentationTranslationKey.GENERATE_MODEL_DOCUMENTATION_RESULT)}
                        lastStepButtonLabel={_transl(ModelDocumentationTranslationKey.GENERATE_MODEL_DOCUMENTATION_BUTTON)}
                        lastStepAction={() => generateModelDocumentation()}
                        lastStepActionSuccessProcessor={(result) => {
                            const response = result as AjaxResponse;
                            const disposition = response.xhr.getResponseHeader('Content-Disposition') as string;
                            const fileName = disposition.substring(disposition?.indexOf("filename=") + 9);
                            const blob = new Blob([response.response]);

                            BlobUtils.saveBlob(blob, fileName);
                        }}
                        lastStepActionInProgressText={_transl(ModelDocumentationTranslationKey.GENERATE_MODEL_DOCUMENTATION_IN_PROGRESS)}
                        lastStepActionSuccessfulText={_transl(ModelDocumentationTranslationKey.GENERATE_MODEL_DOCUMENTATION_IS_DONE)}
                        lastStepActionSuccessfulTextAdditionalInfo={_transl(ModelDocumentationTranslationKey.GENERATE_MODEL_DOCUMENTATION_IS_DONE_ADDITIONAL_INFO)}
                        lastStepActionFailedText={_transl(ModelDocumentationTranslationKey.GENERATE_MODEL_DOCUMENTATION_FAILED)}
                        cancelWizard={() => cancelWizard()}
                        wizardGridMinHeight={"25em"}
                />
            </div>
        </Paper>
    );
}